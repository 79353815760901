import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../Layout";
import {
  Col,
  Empty,
  Pagination,
  Result,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAssetSparePartHistory } from "../../services/assets.services";
import {
  convertUTCToLocalFormat,
  formatNumber,
  scrollToTopTable,
  tableComponents,
} from "../../helpers/utility";
import { PAGESIZE } from "../../constants/defaultKeys";
import dayjs from "dayjs";

const AssetSparePartHistoryTable = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [sparePartData, setSparePartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const { assetCode, source } = location.state || {};

  const fetchAssetSparePartHistory = async () => {
    let query = { page: pageNumber };
    setLoading(true);
    try {
      const res = await getAssetSparePartHistory(params?.id, query);
      setSparePartData(res?.data);
    } catch (error) {
      setSparePartData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAssetSparePartHistory();
      scrollToTopTable(tableRef);
    };
    fetchData();
  }, [pageNumber]);

  const tableColumns = [
    {
      title: "Spare part code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Task code",
      dataIndex: "task_code",
      key: "task_code",
      render: (_, record) => {
        let taskId = record?.workorder?._id || record?.occurrence?._id || "";
        let taskCode =
          record?.workorder?.code || record?.occurrence?.code || "";
        let taskType = taskCode?.includes("WO")
          ? "work-orders"
          : taskCode?.includes("INS")
          ? "inspections"
          : "maintenances";
        if (taskId) {
          return (
            <Typography
              style={{ color: "#1677ff", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/${taskType}/${taskId}${
                    taskType === "work-orders" ? "" : "/job"
                  }`,
                  {
                    state: {
                      source: `/assets/${params?.id}/spare-part-history`,
                      assetCode: assetCode,
                    },
                  }
                )
              }
            >
              {taskCode}
            </Typography>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Spare part used date",
      dataIndex: "requested_date",
      key: "requested_date",
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY")
            : "-"}
        </>
      ),
    },
    {
      title: "Quantity used",
      dataIndex: "requested_quantity",
      key: "requested_quantity",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (val, record) =>
        `₹ ${formatNumber(
          (Number(val?.toFixed(2)) || 0) *
            (Number(record?.requested_quantity) || 0)
        )}`,
    },
  ];

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          <LeftOutlined
            style={{ marginRight: "6px" }}
            onClick={() =>
              navigate(`/assets/${params?.id}`, { state: { source: source } })
            }
          />
          {`Spare Part History for Asset-${assetCode}`}
        </Typography>
      }
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Table
            ref={tableRef}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            bordered
            columns={tableColumns}
            dataSource={sparePartData?.inventoryRequests || []}
            pagination={false}
            scroll={{
              x: 1200,
              y: "60vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No spare part history found</Typography>}
                />
              ),
            }}
            components={tableComponents}
          />
        </Col>
        {sparePartData?.totalInventoryRequests > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={sparePartData?.totalInventoryRequests}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default AssetSparePartHistoryTable;
