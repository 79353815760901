import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import {
  createInventoryRequest,
  updateInventoryRequest,
} from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";

const SparePartForm = ({
  form,
  inventoryList,
  plId,
  selectedPlAssets,
  cancelFunc,
  editDetails,
  orderId,
  type,
  refreshCall,
}) => {
  const params = useParams();
  const { userDetails } = useContext(UserContext);
  const [form1] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editDetails) {
      form1.setFieldsValue({
        spare_asset: editDetails?.asset?._id || undefined,
        spare_part: editDetails?.inventory?._id,
        quantity: editDetails?.requested_quantity || 0,
        cost: editDetails?.inventory?.cost || 0,
        total_cost:
          Number(editDetails?.requested_quantity || 0) *
          Number(editDetails?.inventory?.cost || 0),
        inventory_text: editDetails?.inventory_text,
      });
    }
  }, [editDetails]);

  const getSparePartOptions = (data) => {
    if (data?.length) {
      return data?.map((item) => ({
        ...item,
        label: (
          <Space style={{ gap: 8 }}>
            <Typography>{item?.name}</Typography>
            <Tag className={tagStyles.smRndTag}>{`Cost: ${item?.cost}`}</Tag>
          </Space>
        ),
        value: item?._id,
      }));
    } else {
      return [];
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    let payload = {};
    if (!editDetails) {
      if (type === "WO") {
        payload = { ...payload, workorder: orderId };
      } else if (type === "PM") {
        payload = { ...payload, occurrence: orderId };
      }
      payload = {
        ...payload,
        asset: plId ? values?.spare_asset : form.getFieldValue("asset"),
      };
      if (userDetails?.activeOrg?.organization?.settings?.inventory_module) {
        payload = { ...payload, inventory_id: values?.spare_part };
      }
    }
    if (userDetails?.activeOrg?.organization?.settings?.inventory_module) {
      payload = { ...payload, requested_quantity: Number(values?.quantity) };
    } else if (
      userDetails?.activeOrg?.organization?.settings?.track_spare_parts
    ) {
      payload = { ...payload, inventory_text: values?.inventory_text };
    }
    let ids = {
      inventoryRequestId: editDetails?.inventory_request_id,
      assetId: editDetails?.asset?._id,
      inventoryId: editDetails?._id,
    };
    try {
      const resp = editDetails
        ? await updateInventoryRequest(ids, payload)
        : await createInventoryRequest([payload]);
      resp &&
        message.success(
          editDetails
            ? resp?.data?.message
            : "Inventory request created successfully"
        );
      resp && cancelFunc();
      resp && params?.id && refreshCall();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (value) => {
    let currCost = form1.getFieldValue("cost") || 0;
    form1.setFieldsValue({
      quantity: value,
      total_cost: (Number(value) || 0) * Number(currCost),
    });
  };

  const onSparePartSelect = (value) => {
    let sparePartObj = inventoryList?.filter((s) => s?._id === value)[0];
    let currQuantity = form1.getFieldValue("quantity") || 0;
    form1.setFieldsValue({
      cost: sparePartObj?.cost || 0,
      total_cost: Number(sparePartObj?.cost || 0) * Number(currQuantity),
    });
  };

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form1}
      onFinish={onFinish}
      style={{ width: "100%" }}
    >
      <Row
        gutter={[16, 0]}
        style={{ width: "100%", marginTop: editDetails ? 0 : 16 }}
      >
        {plId && (
          <Col span={7}>
            <Form.Item
              name="spare_asset"
              rules={[
                {
                  required: true,
                  message: "Please select asset",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Select
                disabled={editDetails}
                placeholder="Select asset"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                optionFilterProp="label"
                options={selectedPlAssets}
              />
            </Form.Item>
          </Col>
        )}
        {userDetails?.activeOrg?.organization?.settings?.inventory_module ? (
          <>
            <Col span={7}>
              <Form.Item
                name="cost"
                style={{ height: "0px", margin: "0px" }}
              ></Form.Item>
              <Form.Item
                name="spare_part"
                rules={[
                  {
                    required: true,
                    message: "Please select spare part",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Select
                  disabled={editDetails}
                  placeholder="Select spare part"
                  style={{ width: "100%" }}
                  className={inputStyles.smSelect}
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={(value) => onSparePartSelect(value)}
                  options={getSparePartOptions(inventoryList)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Space style={{ gap: 0, alignItems: "baseline" }}>
                <Form.Item
                  name="quantity"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      validator: (_, value, callback) => {
                        if (value && value !== "") {
                          if (/^[0-9]+$/.test(value)) {
                            if (Number(value) > 0) {
                              callback();
                            } else {
                              callback(
                                "Please enter a valid quantity greater than 0"
                              );
                            }
                          } else {
                            callback("Please enter a valid number");
                          }
                        } else {
                          callback("Please enter a quantity");
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    className={inputStyles.smInput}
                    placeholder="Enter quantity"
                    onChange={(e) => handleQuantityChange(e?.target?.value)}
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={3}>
              <Form.Item name="total_cost" style={{ width: "100%" }}>
                <Input
                  style={{ width: "100%" }}
                  className={inputStyles.smInput}
                  placeholder="Total cost"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </>
        ) : userDetails?.activeOrg?.organization?.settings
            ?.track_spare_parts ? (
          <Col span={14}>
            <Form.Item
              name="inventory_text"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter spare part name",
                },
              ]}
            >
              <Input
                style={{ width: "100%" }}
                className={inputStyles.smInput}
                placeholder="Enter spare part name"
              />
            </Form.Item>
          </Col>
        ) : (
          ""
        )}
        <Col span={3}>
          <Space style={{ width: "100%", gap: 8 }}>
            <Button
              type=""
              onClick={() => form1.submit()}
              loading={loading}
              style={{ textAlign: "center" }}
              icon={
                <CheckOutlined
                  style={{ fontSize: 20, color: "green", cursor: "pointer" }}
                />
              }
            />

            <Button
              type=""
              disabled={loading}
              style={{
                pointerEvents: loading ? "none" : "auto", 
                opacity: loading ? 0.5 : 1,
              }}
              onClick={() => cancelFunc()}
              icon={
                <CloseOutlined
                  style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                />
              }
            />
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default SparePartForm;
