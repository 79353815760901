import React, { useContext, useEffect, useRef, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Image,
  Input,
  Pagination,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import buttonStyles from "../../../assets/styles/Custom/Button.module.css";
import { useNavigate } from "react-router-dom";
import { getProductionLines } from "../../../services/assets.services";
import defaultImage from "../../../assets/images/defaultImage.png";
import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserProvider";
import { PAGESIZE, createPL } from "../../../constants/defaultKeys";
import "../Assets.css";
import {
  debounce,
  getAssetsPath,
  getSearchDefaultValue,
  scrollToTopTable,
  tableComponents,
} from "../../../helpers/utility";
import { getItem, removeItem, setItem } from "../../../helpers/localStorage";

const ProductionLines = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [productionLines, setProductionLines] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState(false);

  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const fetchProductionLines = async (data, clearLocStorage) => {
    let query = { page: data?.pageNumber };
    if (data?.search) {
      query.name = data?.search;
    }

    setLoading(true);
    try {
      const res = await getProductionLines(query);
      setProductionLines(res?.data);
    } catch (error) {
      setProductionLines(null);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      locStorageData?.search && setSearch(locStorageData?.search);
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  useEffect(() => {
    // fetchProductionLines();
    const fetchData = async (data, status = false) => {
      await fetchProductionLines(data, status);
      scrollToTopTable(tableRef);
    };
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      fetchData(locStorageData, true);
    } else {
      fetchData({
        search: search,
        pageNumber: pageNumber,
      });
    }
  }, [pageNumber, refresh]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const savePlFilters = () => {
    let dataObj = {
      search: search,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const tableColumns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 250,
      fixed: "left",
      render: (val) => <Typography>{val ? val : "-"}</Typography>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (val, record) => (
        <Typography>
          {record?.location?.name ? record?.location?.name : "-"}
        </Typography>
      ),
    },
    {
      title: "Assets",
      dataIndex: "assets",
      key: "assets",
      render: (values) =>
        values && values?.length > 0 ? (
          <Space
            style={{
              gap: 4,
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "stretch",
            }}
          >
            {values?.map((item, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center" }}
              >
                {index > 0 && <ArrowRightOutlined />}
                <div
                  style={{
                    padding: 8,
                    textAlign: "center",
                    width: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Space direction="vertical" style={{ gap: 2 }}>
                    <Image
                      src={
                        item?.asset?.file
                          ? getAssetsPath(item?.asset?.file, storageType)
                          : defaultImage
                      }
                      preview={true}
                      style={{ height: "80px", width: "80px", borderRadius: 8 }}
                    />
                    <Typography
                      className="assetName"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/assets/${item?.asset?._id}`, {
                          state: {
                            source: `/production-lines`,
                          },
                        })
                      }
                    >
                      {item?.asset?.name}
                    </Typography>
                  </Space>
                </div>
              </div>
            ))}
          </Space>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          Production Lines ({productionLines?.productionUnitCount || 0})
        </Typography>
      }
    >
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Table
            ref={tableRef}
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
              >
                <Input.Search
                  size="large"
                  defaultValue={getSearchDefaultValue()}
                  onChange={debounce(handleSearch, 500)}
                  placeholder="Search by production line name"
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                {createPL.includes(userDetails?.activeOrg?.role) && (
                  <Button
                    id="create-production-line"
                    type="primary"
                    className={buttonStyles.mdBtn}
                    onClick={() => navigate("/production-lines/create")}
                  >
                    + Create Production Line
                  </Button>
                )}
              </Space>
            )}
            bordered
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            columns={tableColumns}
            dataSource={productionLines?.assets || []}
            pagination={false}
            onRow={(item) => {
              return {
                onClick: (e) => {
                  navigate(`/production-lines/${item?._id}`);
                  savePlFilters();
                },
                style: { cursor: "pointer" },
              };
            }}
            scroll={{
              x: "max-content",
              y: "60vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No Production Lines found</Typography>}
                />
              ),
            }}
            components={tableComponents}
          />
        </Col>
        {productionLines?.productionUnitCount > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={productionLines?.productionUnitCount}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default ProductionLines;
