import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  Select,
  TreeSelect,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import {
  addInventory,
  updateInventory,
} from "../../services/inventory.services";
import { getTaskTypeApi } from "../../services/organisation.services";

const AddEditInventory = ({
  showDrawer,
  setShowDrawer,
  locations,
  currInventory,
  refreshPage,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const formRef = useRef();
  const [form] = Form.useForm();
  const [typeOptions, setTypeOptions] = useState([]);
  const fetchTypes = async () => {
    try {
      const resp = await getTaskTypeApi();
      const transformedData = resp.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setTypeOptions(transformedData);
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };
  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    if (currInventory) {
      form.setFieldsValue({
        code: currInventory?.code,
        cost: currInventory?.cost,
        available_quantity: currInventory?.available_quantity,
        low_stock_threshold: currInventory?.low_stock_threshold,
        name: currInventory?.name,
        location: currInventory?.location?._id,
        typeObj: currInventory?.typeObj
          ? currInventory?.typeObj?._id
          : undefined,
      });
    }
  }, [currInventory]);

  const onFinish = (values) => {
    let payload = {
      code: values?.code,
      cost: values?.cost ? Number(values?.cost) : 0,
      available_quantity: values?.available_quantity
        ? values?.available_quantity
        : 0,
      low_stock_threshold: values?.low_stock_threshold
        ? values?.low_stock_threshold
        : 0,
      name: values?.name,
      location: values?.location,
      typeObj: values?.typeObj,
      // typeObj: values?.typeObj ? values?.typeObj : "Others",
    };
    if(currInventory){
      payload = {
        ...payload,
        typeObj: values?.typeObj || "",
      }
    }

    setBtnLoading(true);
    const addOrUpdateFunc = currInventory
      ? updateInventory(currInventory?._id, payload)
      : addInventory(payload);
    addOrUpdateFunc
      .then((res) => {
        message.success(res?.data?.message);
        refreshPage();
        setShowDrawer(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      })
      .finally(() => setBtnLoading(false));
  };
  return (
    <Drawer
      footer={
        <Flex justify="flex-end" gap={24}>
          <Button
            onClick={() => setShowDrawer(false)}
            className={btnStyles.mdBtn}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={btnLoading}
            onClick={() => formRef?.current?.submit()}
            className={btnStyles.mdBtn}
          >
            {currInventory ? "Save" : "Add"}
          </Button>
        </Flex>
      }
      title={
        currInventory ? `Inventory-${currInventory?.code}` : "Add Inventory"
      }
      width={"30vw"}
      onClose={() => setShowDrawer(false)}
      open={showDrawer}
    >
      <Form
        ref={formRef}
        form={form}
        name="inventory-form"
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item
          label="Spare part name"
          name={"name"}
          rules={[
            { required: true, message: "Please enter spare part name" },
            {
              whitespace: true,
              message: "Spare part name cannot be empty or contain only spaces",
            },
            {
              min: 3,
              message: "Spare part name should contain atleast 3 characters",
            },
            {
              max: 100,
              message: "Spare part name cannot excced 100 characters",
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter spare part name"
          />
        </Form.Item>
        <Form.Item
          label="Location"
          name={"location"}
          rules={[{ required: true, message: "Please enter location" }]}
        >
          <TreeSelect
            placeholder="Location"
            style={{
              width: "100%",
            }}
            showSearch
            className={inputStyles.smSelect}
            treeData={Object.keys(locations)?.length > 0 && [locations]}
          />
        </Form.Item>
        <div style={{ display: "flex", width: "100%" }}>
          <Form.Item
            rules={[
              {
                whitespace: true,
                message: "Spare code cannot be empty or contain only spaces",
              },
              { required: true, message: "Please enter spare code" },
            ]}
            label="Spare code"
            name="code"
            style={{ width: "60%" }}
          >
            <Input
              className={inputStyles.smInput}
              style={{
                width: "100%",
                borderEndEndRadius: "0px",
                borderStartEndRadius: "0px",
              }}
              placeholder="Enter code"
            />
          </Form.Item>
          <Form.Item label=" " style={{ width: "40%" }}>
            <Button
              onClick={() =>
                form.setFieldsValue({
                  code: Math.floor(10000 + Math.random() * 90000).toString(),
                })
              }
              type="primary"
              style={{
                borderLeft: "0px",
                borderEndStartRadius: "0px",
                borderStartStartRadius: "0px",
                height: "34px",
                width: "100%",
              }}
              className={btnStyles.mdBtn}
            >
              Generate
            </Button>
          </Form.Item>
        </div>
        <Form.Item label="Type" name={"typeObj"}>
          <Select
            placeholder="Select type"
            style={{
              width: "100%",
            }}
            className={inputStyles.smSelect}
            // allowClear={!currInventory ? true : false}
            options={typeOptions}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="Quantity available"
          name={"available_quantity"}
          rules={[
            {
              validator: (_, value, callback) => {
                if ((value && value !== "") || value === 0) {
                  if (/^[0-9]+$/.test(value)) {
                    if (Number(value) >= 0) {
                      callback();
                    } else {
                      callback("Please enter a valid quantity");
                    }
                  } else {
                    callback("Please enter a valid number");
                  }
                } else {
                  callback("Please enter a valid quantity");
                }
              },
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter quantity"
          />
        </Form.Item>
        <Form.Item
          label="Low stock threshold"
          name={"low_stock_threshold"}
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (/^[0-9]+$/.test(value)) {
                    callback();
                  } else {
                    callback("Please enter a valid number");
                  }
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter low stock threshold"
          />
        </Form.Item>
        <Form.Item
          label="Cost per piece"
          name={"cost"}
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (/^\d+(\.\d{1,2})?$/.test(value)) {
                    callback();
                  } else {
                    callback(
                      "Please enter a valid cost with at most 2 decimal places"
                    );
                  }
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter cost"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddEditInventory;
