import {
  Checkbox,
  Col,
  Flex,
  Image,
  Input,
  Popconfirm,
  Popover,
  Row,
  Tabs,
  Typography,
} from "antd";

import React, { useContext, useState } from "react";
import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  InfoCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import UploadSectionDocs from "./UploadSectionDocs";
import { deleteDocs, getAssetsPath } from "../../../../helpers/utility";
import UploadSectionVideo from "./UploadSectionVideo";
import VideoPlayer from "../../../Blocks/VideoPlayer";
import { UserContext } from "../../../../context/UserProvider";

const SectionQuestion = ({
  index,
  questionData,
  setSopContent,
  sopContent,
  disabled,
  ref,
}) => {
  const [editableStr, setEditableStr] = useState(questionData?.question);
  const [edit, setEdit] = useState(false);
  const [isModalOpen, setModalOpen] = useState({ open: false, type: "" });
  const [activeTab, setActiveTab] = useState("");
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const plainOptions = [
    { label: "Enter reason", value: "reason" },
    { label: "See the below helper text", value: "helperText" },
    { label: "Upload image", value: "image" },
  ];

  const handleHelperTextChange = (eachChoice, value) => {
    if (questionData.id) {
      setSopContent((prevSopContent) =>
        prevSopContent.map((eachSOP) =>
          eachSOP.id === questionData.parentId
            ? {
                ...eachSOP,
                procedures: eachSOP.procedures.map((eachQ) =>
                  eachQ.id === questionData.id
                    ? {
                        ...eachQ,
                        things: {
                          ...eachQ.things,
                          [eachChoice]: {
                            ...questionData?.things?.[eachChoice],
                            helperText: value,
                          },
                        },
                      }
                    : eachQ
                ),
              }
            : eachSOP
        )
      );
    }
  };

  const items = Object.keys(questionData?.things)?.map((eachChoice, index) => ({
    key: index,
    label: eachChoice,
    children: (
      <>
        <Typography style={{ marginBottom: "12px" }}>
          If user response is{" "}
          <span style={{ color: questionData?.things?.[eachChoice]?.color }}>
            {eachChoice}
          </span>
          , then he/she should{" "}
        </Typography>
        <Checkbox.Group
          value={questionData?.things?.[eachChoice].enable}
          onChange={(e) => {
            if (questionData.id) {
              setSopContent((prevSopContent) =>
                prevSopContent.map((eachSOP) =>
                  eachSOP.id === questionData.parentId
                    ? {
                        ...eachSOP,
                        procedures: eachSOP.procedures.map((eachQ) =>
                          eachQ.id === questionData.id
                            ? {
                                ...eachQ,
                                things: {
                                  ...eachQ.things,
                                  [eachChoice]: {
                                    ...questionData?.things?.[eachChoice],
                                    enable: e,
                                  },
                                },
                              }
                            : eachQ
                        ),
                      }
                    : eachSOP
                )
              );
            }
          }}
        >
          <Row gutter={[0, 12]}>
            {plainOptions?.map((each) => (
              <Col span={12} key={each.value}>
                <Checkbox value={each?.value}>{each?.label}</Checkbox>
              </Col>
            ))}
            {questionData?.things?.[eachChoice]?.helperText && (
              <Col
                span={12}
                className="flex-center"
                style={{ justifyContent: "flex-start", gap: "6px" }}
              >
                <InfoCircleFilled style={{ color: "#007aff" }} />
                <Typography.Paragraph
                  editable={{
                    onChange: (value) =>
                      handleHelperTextChange(eachChoice, value),
                  }}
                  style={{ fontSize: "13px", marginBottom: "0px" }}
                >
                  {questionData?.things?.[eachChoice]?.helperText}
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
        </Checkbox.Group>
      </>
    ),
  }));

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setEdit(false);
      if (questionData.id) {
        setSopContent((prevSopContent) =>
          prevSopContent.map((eachSOP) =>
            eachSOP.id === questionData.parentId
              ? {
                  ...eachSOP,
                  procedures: eachSOP.procedures.map((eachQ) =>
                    eachQ.id === questionData.id
                      ? {
                          ...eachQ,
                          question: e?.target?.value,
                        }
                      : eachQ
                  ),
                }
              : eachSOP
          )
        );
      }
    }
  };

  return (
    <Row
      gutter={[24, 24]}
      id={questionData?.id}
      style={{
        marginBottom: "24px",
        borderBottom: "1px solid rgb(205 210 219)",
        paddingBottom: "24px",
      }}
    >
      <Col span={14}>
        <Flex gap={12} align="center">
          {index + 1}.
          {edit ? (
            <Input
              value={editableStr}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                setEditableStr(e?.target?.value);
              }}
              onBlur={(e) => {
                setEdit(false);
                if (questionData.id) {
                  setSopContent((prevSopContent) =>
                    prevSopContent.map((eachSOP) =>
                      eachSOP.id === questionData.parentId
                        ? {
                            ...eachSOP,
                            procedures: eachSOP.procedures.map((eachQ) =>
                              eachQ.id === questionData.id
                                ? {
                                    ...eachQ,
                                    question: e?.target?.value,
                                  }
                                : eachQ
                            ),
                          }
                        : eachSOP
                    )
                  );
                }
              }}
            />
          ) : (
            <Typography style={{ marginBottom: "0px" }}>
              {questionData?.question}
            </Typography>
          )}
          {!edit && <EditOutlined onClick={() => setEdit(!edit)} />}
          {!disabled && (
            <>
              <Popconfirm
                icon={null}
                description="Are you sure to delete this question?"
                onConfirm={(e) => {
                  let data = sopContent.map((eachSOp) => {
                    if (eachSOp?.id == questionData?.parentId) {
                      return {
                        ...eachSOp,
                        procedures: eachSOp.procedures?.filter(
                          (eachQ) => eachQ?.id !== questionData?.id
                        ),
                      };
                    } else {
                      return eachSOp;
                    }
                  });
                  setSopContent(data);
                }}
              >
                <DeleteOutlined style={{ color: "red" }} />
              </Popconfirm>
              <UploadSectionDocs
                sopContent={sopContent}
                setSopContent={setSopContent}
                questionData={questionData}
              />
              {questionData?.src?.length > 1 ? (
                <Popover content="Maximum 1 Video can be uploaded">
                  {" "}
                  <UploadOutlined />
                </Popover>
              ) : (
                <UploadOutlined
                  onClick={() => {
                    setModalOpen({ open: true, type: "video" });
                    setActiveTab("add_yt_link");
                  }}
                />
              )}
            </>
          )}
        </Flex>
        <div style={{ marginLeft: "24px" }}>
          <Tabs
            items={items}
            defaultActiveKey={Object.keys(questionData?.things)[0]}
          />
        </div>
      </Col>
      <Col
        span={10}
        style={{
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "24px",
          display: "flex",
        }}
      >
        <div style={{ justifyContent: "flex-start" }} className="flex-center">
          {questionData?.images?.map((eachImg) => (
            <div
              key={eachImg}
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <CloseCircleFilled
                onClick={() => {
                  if (questionData.id) {
                    deleteDocs(false, eachImg, storageType);
                    setSopContent((prevSopContent) =>
                      prevSopContent.map((eachSOP) =>
                        eachSOP.id === questionData.parentId
                          ? {
                              ...eachSOP,
                              procedures: eachSOP.procedures.map((eachQ) =>
                                eachQ.id === questionData.id
                                  ? {
                                      ...eachQ,
                                      images: eachQ?.images?.filter(
                                        (currImg) => eachImg != currImg
                                      ),
                                    }
                                  : eachQ
                              ),
                            }
                          : eachSOP
                      )
                    );
                  }
                }}
                style={{
                  width: "100%",
                  position: "relative",
                }}
              />
              <Image
                src={getAssetsPath(eachImg, storageType)}
                alt="Image"
                style={{ zIndex: 100, height: "auto", width: "100%" }}
              />
            </div>
          ))}
        </div>
        {questionData?.src && (
          <div
            style={{
              width: "100%",
              minHeight: "200px",
              position: "relative",
            }}
          >
            <CloseCircleFilled
              onClick={() => {
                if (questionData.id) {
                  setSopContent((prevSopContent) =>
                    prevSopContent.map((eachSOP) =>
                      eachSOP.id === questionData.parentId
                        ? {
                            ...eachSOP,
                            procedures: eachSOP.procedures.map((eachQ) =>
                              eachQ.id === questionData.id
                                ? {
                                    ...eachQ,
                                    src: "",
                                    previewURL: "",
                                    thumbnail: "",
                                  }
                                : eachQ
                            ),
                          }
                        : eachSOP
                    )
                  );
                }
              }}
              style={{
                position: "absolute",
                top: -8,
                right: -10,
                zIndex: 1,
                color: "red",
                fontSize: "18px",
              }}
            />
            <VideoPlayer
              url={questionData.previewURL || questionData.src}
              width="100%"
              height="200px"
            />
          </div>
        )}
      </Col>
      {isModalOpen.open && (
        <UploadSectionVideo
          isUploadLimitReached={false}
          data={questionData}
          sopContent={sopContent}
          setSopContent={setSopContent}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </Row>
  );
};

export default SectionQuestion;
