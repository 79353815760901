import React, { useEffect, useState } from "react";
import { Button, Image, Tag, Typography, message } from "antd";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import textStyles from "../../../assets/styles/Custom/Typography.module.css";
import buttonStyles from "../../../assets/styles/Custom/Button.module.css";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";

import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { forgotPasswordApi } from "../../../services/users.services";

const CheckEmail = ({
  loginDetails,
  setLoginDetails,
  setLoginType,
  orgDetails,
}) => {
  const [resend, setResend] = useState(false);
  const [disable, setDisable] = useState(false);
  const [seconds, setSeconds] = useState(false);

  const onResendLink = async () => {
    setSeconds(30);
    try {
      let res = await forgotPasswordApi({ email: loginDetails?.email });
      if (res) {
        setResend(true);
        setDisable(true);
        setTimeout(() => {
          setDisable(false);
        }, 30000);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div style={{ textAlign: "center" }}>
      <Image
        src={orgDetails.logo || darkLogo}
        preview={false}
        style={{ height: "100px", width: "auto", marginBottom: "12px" }}
      />
      <Typography className={textStyles.h6}>Check your email.</Typography>

      <Typography
        className={textStyles.body3}
        style={{ color: "#475467", marginBlock: "12px" }}
      >
        Password reset link has been sent to
      </Typography>
      <Typography
        className="hyperlink flex-center"
        style={{ gap: "2px" }}
        onClick={() => {
          setLoginDetails({ mobile: "", token: "", email: "" });
          setLoginType("forgot");
        }}
      >
        {loginDetails.email}
        <EditOutlined />
      </Typography>
      <Typography
        className={textStyles.body3}
        style={{ color: "#475467", marginBlock: "24px" }}
      >
        Can't find the email? Try checking your spam folder.
      </Typography>
      {resend ? (
        <>
          <Tag className={tagStyles.ldRndTag} color="success">
            We have sent you another link.
            <Button
              disabled={disable}
              className={buttonStyles.mdBtn}
              style={{
                padding: "2px 6px",
                borderRadius: "16px",
                gap: "2px",
              }}
              onClick={onResendLink}
            >
              {seconds > 0 ? (
                `Resend code in 00:${seconds}`
              ) : (
                <span>Resend Link</span>
              )}
            </Button>
          </Tag>
        </>
      ) : (
        <Tag className={tagStyles.ldRndTag} color="purple">
          Didn’t receive the email
          <Button
            className={buttonStyles.mdBtn}
            style={{ padding: "2px 6px", borderRadius: "16px", gap: "2px" }}
            type="primary"
            onClick={onResendLink}
          >
            Resend link
            <ArrowRightOutlined />
          </Button>
        </Tag>
      )}
      <br />
      <Button
        type="link"
        style={{ marginTop: "32px" }}
        className={`${buttonStyles.mdBtn} ${buttonStyles.textBtn}`}
        onClick={() => {
          setLoginDetails({ mobile: "", token: "", email: "" });
          setLoginType("email");
        }}
      >
        <ArrowLeftOutlined />
        Back to log in
      </Button>
    </div>
  );
};

export default CheckEmail;
