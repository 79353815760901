import {
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tag,
  Typography,
  TreeSelect,
  DatePicker,
  ConfigProvider,
  Breadcrumb,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getAllAssetsApi,
  getAssetLocationByIDApi,
  getProductionLineLocationByIDApi,
} from "../../../services/assets.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { getAssetsPath, priorityOptions } from "../../../helpers/utility";
import { getUsersandGroupsApi } from "../../../services/users.services";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import { INSPECTION, ONPREMISE } from "../../../constants/defaultKeys";
import { getTaskTypeApi } from "../../../services/organisation.services";

const GeneralDetails = ({
  form,
  maintanaceDetails,
  edit,
  setSopData,
  type,
  productionLines,
  setPlId,
}) => {
  // console.log("mai ",form.getFieldValue("subTypeObj"))
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });
  const [techinicians, setTechinicans] = useState([]);
  const [location, setLocation] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [typeOptions, setTypeOptions] = useState([]);

  const fetchPMTypes = async () => {
    try {
      const resp = await getTaskTypeApi();
      const transformedData = resp.data.map((item) => ({
        label: item?.name,
        value: item?._id,
      }));
      setTypeOptions(transformedData);
      console.log(transformedData);
    } catch (error) {
      console.error("Error fetching  types:", error);
    }
  };

  const handleAssetChange = (value, e) => {
    getLocation(value, "asset");
    if (e?.image) {
      setImage({
        name: e?.image,
        status: e?.status,
      });
    } else {
      setImage({ name: "", status: "" });
    }
    setPlId(null);
    form.setFieldValue("production_line", undefined);
  };

  const handleProductionLineChange = (value) => {
    getLocation(value, "production-line");
    // let plObject = productionLines?.filter((i) => i?.value === value)?.[0];
    // setLocation([{ title: plObject?.location?.name }]);
    setImage({ name: "", status: "" });
    setPlId(value);
    form.setFieldValue("asset", undefined);
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          code: asset?.code,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {}
  };

  const getTecniciansandGroups = async (searchValue) => {
    try {
      const params = {};
      params.role = "technician";

      const resp = await getUsersandGroupsApi(params);
      let assignedUsers = [],
        assignedGroups = [];

      const users = resp?.data?.users?.map((user) => {
        maintanaceDetails?.assigned_users?.includes(user?._id) &&
          assignedUsers.push(user?._id);
        return {
          value: user._id,
          title: `${user?.first_name} ${user?.last_name || ""}`,
          group: false,
          id: user?._id,
        };
      });

      const groups = resp?.data?.groups?.map((group) => {
        maintanaceDetails?.assigned_groups?.includes(group?._id) &&
          assignedGroups.push(group?._id);
        return {
          value: group._id,
          title: group.name,
          group: true,
          children: group.members.map((member) => ({
            value: `${member._id}-${group?._id}`,
            title: `${member?.first_name} ${member?.last_name || ""}`,
            group: false,
            id: member?._id,
          })),
        };
      });

      setTechinicans([...users, ...groups]);
      form.setFieldsValue({
        assigned_users: assignedUsers,
        assigned_groups: assignedGroups,
        assigned_list: [...assignedGroups, ...assignedUsers],
      });
    } catch (error) {}
  };
  const getLocation = async (id, type = "asset") => {
    try {
      const resp =
        type === "production-line"
          ? await getProductionLineLocationByIDApi(id)
          : await getAssetLocationByIDApi(id);
      // const resp = await getAssetLocationByIDApi(id);
      // const location = resp?.data?.map((each) => ({ title: each?.name }));
      if (resp?.data) {
        setLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
    } catch (error) {}
  };

  useEffect(() => {
    // console.log("maintanaceDetails", maintanaceDetails);
    if (
      maintanaceDetails?.asset ||
      maintanaceDetails?.maintenance?.asset?._id
    ) {
      getLocation(
        maintanaceDetails?.asset || maintanaceDetails?.maintenance?.asset?._id,
        "asset"
      );
    } else if (
      maintanaceDetails?.production_line ||
      maintanaceDetails?.maintenance?.production_line?._id
    ) {
      getLocation(
        maintanaceDetails?.production_line ||
          maintanaceDetails?.maintenance?.production_line?._id,
        "production-line"
      );
    }
    // const id =
    //   maintanaceDetails?.asset ||
    //   maintanaceDetails?.maintenance?.asset?._id;
    // id && getLocation(id);
    setImage({
      name:
        form.getFieldValue("asset_image") ||
        (storageType == ONPREMISE
          ? maintanaceDetails?.asset?.file || ""
          : maintanaceDetails?.asset?.file ||
            maintanaceDetails?.maintenance?.asset?.file),
      status:
        form.getFieldValue("asset_status") || maintanaceDetails?.asset?.status,
    });
  }, [form, maintanaceDetails]);

  useEffect(() => {
    fetchPMTypes();
    getAssets();
  }, []);

  useEffect(() => {
    if (
      !params?.id ||
      (params?.id && maintanaceDetails && Object.keys(maintanaceDetails).length)
    ) {
      getTecniciansandGroups();
    }
  }, [maintanaceDetails]);

  return (
    <Card
      title={
        type == INSPECTION
          ? "Inspection Details"
          : "Preventive Maintenance Details"
      }
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name={"asset_status"}
            style={{ height: "0px", margin: "0px" }}
          ></Form.Item>

          <Form.Item
            style={{ height: "0px", margin: "0px" }}
            name={"asset_image"}
          ></Form.Item>
          <Form.Item
            label="Title"
            name={"name"}
            rules={[
              { required: true, message: "Please enter title" },
              {
                whitespace: true,
                message:
                  "Maintenance title cannot be empty or contain only spaces",
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="Enter title here"
              disabled={!edit || params?.type}
              className={inputStyles.smInput}
            />
          </Form.Item>
          <Flex
            style={{
              width: "100%",
              flexDirection: "column",
              marginBottom: "12px",
            }}
          >
            <Form.Item
              style={{
                width: "100%",
                marginBlock: "0px 6px",
              }}
              label={"Asset"}
              name={"asset"}
            >
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select asset"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.name
                    ?.toUpperCase()
                    .includes(inputValue.toUpperCase()) ||
                  option.code?.toString().includes(inputValue)
                }
                onChange={handleAssetChange}
                options={assets}
              />
            </Form.Item>
          </Flex>
          <Typography style={{ textAlign: "center" }}>or</Typography>
          <Form.Item
            style={{
              width: "100%",
              marginBlock: "0px 6px",
            }}
            label={"Production line"}
            name={"production_line"}
          >
            <Select
              disabled={params?.id ? true : false}
              placeholder="Select production line"
              style={{
                width: "100%",
              }}
              className={inputStyles.smSelect}
              onChange={handleProductionLineChange}
              options={productionLines}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
          {location?.length > 0 && (
            <Flex>
              <Form.Item label="Location">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "0px",
                    padding: "4px 12px",
                  }}
                >
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: "#2f3542",
                          lastItemColor: "#2f3542",
                        },
                      },
                    }}
                  >
                    <Breadcrumb separator=">" items={location} />
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Flex>
          )}

          {params?.type && (
            <>
              <Flex gap={12}>
                <Form.Item
                  style={{
                    width: "50%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please select the scheduled date and time",
                    },
                  ]}
                  label="Scheduled to start at"
                  name={"occurrence_date"}
                >
                  <DatePicker
                    disabled={!edit || params?.type}
                    format="YYYY-MM-DD HH:mm"
                    placeholder="Occurence date & time"
                    size="medium"
                    showTime={{
                      defaultValue: dayjs("00:00", "HH:mm"),
                    }}
                    allowClear={false}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: "50%",
                  }}
                  label={"Duration (hours)"}
                  name={"duration"}
                >
                  <Input disabled={!edit || params?.type} />
                </Form.Item>
              </Flex>
            </>
          )}
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_users"}
          ></Form.Item>
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_groups"}
          ></Form.Item>
          <Form.Item
            style={{
              width: "49%",
            }}
            label={`${type} type`}
            name={"subTypeObj"}
          >
            <Select
              disabled={!edit || params?.type}
              placeholder={`Select ${type} type`}
              style={{
                width: "100%",
              }}
              className={inputStyles.smSelect}
              onChange={() => {}}
              options={typeOptions}
              allowClear
            />
          </Form.Item>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Assign technician"}
              name={"assigned_list"}
            >
              <TreeSelect
                allowClear
                multiple={true}
                treeCheckable={true}
                className={inputStyles.smSelect}
                showSearch
                placeholder={"Assign technician"}
                treeData={techinicians}
                style={{ width: "100%" }}
                showCheckedStrategy="SHOW_PARENT"
                filterTreeNode={(input, treeNode) => {
                  const inputValue = input.toUpperCase();
                  const title = treeNode.title.toUpperCase();
                  const match = title.indexOf(inputValue) !== -1;

                  return match;
                }}
                onSelect={(e, values) => {
                  const id = values.value.split("-")[1];
                  if (values?.group) {
                    if (values?.children?.length === 0) {
                      message.info(
                        `There are no technicians present in "${values?.title}" group.`
                      );
                    }
                    form.setFieldsValue({
                      assigned_groups: [
                        ...(form.getFieldValue("assigned_groups") || []),
                        e,
                      ],
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: [
                        ...(form.getFieldValue("assigned_users") || []),
                        id || e,
                      ],
                    });
                  }
                }}
                disabled={!edit}
                onDeselect={(e, values) => {
                  const id = values.value.split("-")[1];
                  const updatedAssignedGroups = form
                    .getFieldValue("assigned_groups")
                    ?.filter((val) => val !== e);
                  const updatedAssignedUsers = form
                    .getFieldValue("assigned_users")
                    ?.filter((val) => val !== (id || e));

                  if (values?.group) {
                    form.setFieldsValue({
                      assigned_groups: updatedAssignedGroups,
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: updatedAssignedUsers,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              name={"priority"}
              label="Priority"
            >
              <Select
                disabled={!edit || params?.type}
                placeholder="Select priority"
                defaultValue={"moderate"}
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                onChange={() => {}}
                options={priorityOptions}
              />
            </Form.Item>
          </Flex>
        </Col>
        <Col span={12} style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
            {image?.name ? (
              <Image
                src={getAssetsPath(image?.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "300px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
