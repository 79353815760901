import dayjs from "dayjs";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  monthOptions,
  weekDays,
} from "../../../helpers/utility";
import {
  createMaintananceApi,
  deleteMaintananceApi,
  deleteOccurenceApi,
  getMaintananceByIDApi,
  getOccurenceByIDApi,
  updateMaintananceApi,
  updateOccurenceApi,
} from "../../../services/maintenance.services";
import { message } from "antd";
import { INSPECTION } from "../../../constants/defaultKeys";

export function formatDate(date) {
  return dayjs(date).format("MM/DD/YYYY");
}

export const getIntialValues = () => {
  const today = new Date();
  // Get the day of the month
  const currentDayOfMonth = today.getUTCDate();

  // Get the day (e.g., Monday, Tuesday, etc.)
  const daysOfWeek = weekDays?.map((each) => each?.label);
  const currentDay = daysOfWeek[today.getDay() - 1];

  const months = monthOptions?.map((each) => each?.label);
  const weekdayIndices = ["first", "second", "third", "fourth", "last"];
  // Get the weekday index (0 for Sunday, 1 for Monday, etc.)

  const currentDate = today.getDate(); // Get the day of the month (1-31)

  // Calculate the week number based on the current day
  const currentDateIndex = Math.ceil(currentDate / 7);
  const currentWeekdayIndexString = weekdayIndices[currentDateIndex];

  const currentMonth = months[today.getMonth()];
  return {
    day_of_month: currentDayOfMonth,
    week_days: [currentDay],
    month_option: currentWeekdayIndexString,
    month: currentMonth,
    repeat: "daily",
    start_date: dayjs().startOf("day"),
  };
};

export function generateScheduleString(
  startDate,
  startTime,
  recurrenceType,
  dayOfWeek,
  dayOfMonth,
  monthOption,
  month,
  every,
  setOccurString,
  typeofRec
) {
  let scheduleString = "Occurs ";
  let dayOfWeekStr = dayOfWeek?.join(", ") || "";

  switch (recurrenceType) {
    case "daily":
      scheduleString += every > 1 ? `every ${every} days` : "every day";
      break;
    case "weekly":
      const weeklyString =
        every > 1
          ? `every ${every} weeks on ${dayOfWeekStr}`
          : `every ${dayOfWeekStr}`;
      scheduleString += weeklyString;
      break;
    case "monthly":
      scheduleString += "";
      if (typeofRec === 1) {
        scheduleString +=
          every > 1
            ? ` every ${every} months on day ${dayOfMonth}`
            : `	
            every month on day ${dayOfMonth}`;
      } else {
        scheduleString +=
          every > 1
            ? `every ${every} months on ${monthOption} ${dayOfWeekStr}`
            : `every month on ${monthOption} ${dayOfWeekStr}`;
      }
      break;
    case "yearly":
      scheduleString += "every year on ";
      if (typeofRec === 1) {
        scheduleString += `day ${dayOfMonth} of ${month}`;
      } else {
        scheduleString += `${monthOption} ${dayOfWeekStr} of ${month}`;
      }
      break;
    default:
      return "Invalid recurrence type";
  }
  if (startDate) {
    scheduleString += ` at ${dayjs(startDate).format("hh:mm A")}`;
  }
  setOccurString(startDate ? scheduleString : "");
}

export const handleDynamicValidations = (
  changedValues,
  values,
  form,
  setOccurString,
  typeofRec
) => {
  const recurFields = [
    "week_days",
    "month_option",
    "month",
    "type_of_rec",
    "day_of_month",
    "every",
    "start_date",
  ];
  if (values["start_date"] || values["end_date"]) {
    const startDate = values["start_date"];
    const endDate = values["end_date"];
    const repeat = values["repeat"];

    if (startDate && endDate) {
      const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
      const fiveYearsInMilliseconds = 5 * 365 * 24 * 60 * 60 * 1000;
      const dateDiff = endDate - startDate;

      if (repeat === "yearly") {
        if (dateDiff > fiveYearsInMilliseconds) {
          const errorFields = [
            {
              value: startDate,
              name: "start_date",
              errors: [
                "Gap between start date and end date cannot be greater than 5 years",
              ],
            },
            {
              value: endDate,
              name: "end_date",
              errors: [
                "Gap between start date and end date cannot be greater than 5 years",
              ],
            },
          ];
          form.setFields(errorFields);
        } else {
          const errorFields = [
            {
              value: startDate,
              name: "start_date",
              errors: [],
            },
            {
              value: endDate,
              name: "end_date",
              errors: [],
            },
          ];
          form.setFields(errorFields);
        }
      } else {
        if (dateDiff > oneYearInMilliseconds) {
          const errorFields = [
            {
              value: startDate,
              name: "start_date",
              errors: [
                "Gap between start date and end date cannot be greater than 1 year",
              ],
            },
            {
              value: endDate,
              name: "end_date",
              errors: [
                "Gap between start date and end date cannot be greater than 1 year",
              ],
            },
          ];
          form.setFields(errorFields);
        } else {
          const errorFields = [
            {
              value: startDate,
              name: "start_date",
              errors: [],
            },
            {
              value: endDate,
              name: "end_date",
              errors: [],
            },
          ];
          form.setFields(errorFields);
        }
      }
    }
  }

  if (changedValues["repeat"]) {
    form.resetFields(recurFields?.filter((each) => each != "start_date"));
    const initialValues = getIntialValues();
    generateScheduleString(
      values["start_date"],
      values["start_time"],
      changedValues["repeat"],
      initialValues.week_days,
      initialValues.day_of_month,
      initialValues.month_option,
      initialValues.month,
      1,
      setOccurString,
      typeofRec
    );
  }
  if (recurFields.some((key) => changedValues[key])) {
    generateScheduleString(
      values["start_date"],
      values["start_time"],
      values["repeat"],
      values["week_days"],
      values["day_of_month"],
      values["month_option"],
      values["month"],
      values["every"],
      setOccurString,
      typeofRec
    );
  }
};

export const getJob = async (
  form,
  params,
  setMaintanceDetails,
  setSopData,
  setWorkOrderID,
  setAnswers,
  setIsInspection,
  setPlId
) => {
  try {
    const resp = await getOccurenceByIDApi(params?.id);
    let data = {
      ...resp?.data?.occurrence,
      asset: resp?.data?.occurrence?.maintenance?.asset?._id,
      production_line: resp?.data?.occurrence?.production_line?._id,
      subTypeObj: resp?.data?.occurrence?.maintenance?.subTypeObj,
      name: resp?.data?.occurrence?.maintenance?.name,
      code: resp?.data?.occurrence?.maintenance?.code,
      asset_image: resp?.data?.occurrence?.maintenance?.asset?.file,
      asset_status: resp?.data?.occurrence?.maintenance?.asset?.status,
      occurrence_date: resp?.data?.occurrence?.occurrence_date
        ? dayjs(
            convertUTCToLocalFormat(resp?.data?.occurrence?.occurrence_date)
          )
        : null,
      end_date: resp?.data?.occurrence?.occurrence_end_date
        ? dayjs(
            convertUTCToLocalFormat(resp?.data?.occurrence?.occurrence_end_date)
          )
        : null,
      duration: resp?.data?.occurrence?.duration,
      start_date: resp?.data?.occurrence?.occurrence_date
        ? dayjs(
            convertUTCToLocalFormat(resp?.data?.occurrence?.occurrence_date)
          )
        : null,
      sop: resp?.data?.occurrence?.maintenance?.sop?._id,
      assigned_groups: resp?.data?.occurrence?.assigned_groups?.map(
        (each) => each?._id
      ),
      assigned_users: resp?.data?.occurrence?.assigned_users?.map(
        (each) => each?._id
      ),
      assigned_list: [
        ...(resp?.data?.occurrence?.assigned_groups?.map((each) => each?._id) ||
          []),
        ...(resp?.data?.occurrence?.assigned_users?.map((each) => each?._id) ||
          []),
      ],
    };
    setMaintanceDetails(data);
    setPlId(data?.production_line);
    setSopData(resp?.data?.occurrence?.maintenance?.sop || {});
    resp?.data?.occurrence?.maintenance?.sop?._id &&
      resp?.data?.occurrence?.maintenance?.sop?.is_inspection &&
      setIsInspection(true);
    form.setFieldsValue({ ...data, subTypeObj: data?.subTypeObj?._id });
    // form.setFieldsValue({...data,subTypeObj:{
    //   label: resp?.data?.occurrence?.subTypeObj?.name,
    //   value: resp?.data?.occurrence?.subTypeObj?._id
    // }});
    setWorkOrderID(resp?.data?.occurrence?.maintenance?._id);
    setAnswers(resp?.data?.sopSubmission?.responses || {});
  } catch (error) {}
};

export const getMaintanace = async (
  navigate,
  form,
  params,
  setTypeOfRec,
  setWoDocuments,
  setMaintanceDetails,
  setSopData,
  setAnswers,
  occDate,
  setOccurString,
  setIsInspection,
  setPlId
) => {
  try {
    const resp = await getMaintananceByIDApi(params?.id, occDate);
    let data = {
      ...resp?.data,
      asset: resp?.data?.asset?._id,
      production_line: resp?.data?.production_line?._id,
      asset_image: resp?.data.asset?.file,
      asset_status: resp?.data?.asset?.status,
      end_date: resp?.data?.end_date
        ? dayjs(convertUTCToLocalFormat(resp?.data?.end_date))
        : null,
      type_of_rec: !resp?.data?.day_of_month ? 2 : 1,
      start_date: resp?.data?.start_date
        ? dayjs(convertUTCToLocalFormat(resp?.data?.start_date))
        : null,
      sop: resp?.data?.sop?._id,
      assigned_groups: resp?.data?.assigned_groups?.map((each) => each?._id),
      assigned_users: resp?.data?.assigned_users?.map((each) => each?._id),
      assigned_list: [
        ...(resp?.data?.assigned_groups?.map((each) => each?._id) || []),
        ...(resp?.data?.assigned_users?.map((each) => each?._id) || []),
      ],
    };
    if (!resp?.data?.day_of_month) {
      setTypeOfRec(2);
    }
    generateScheduleString(
      resp?.data?.["start_date"]
        ? dayjs(convertUTCToLocalFormat(resp?.data?.["start_date"]))
        : null,
      // resp?.data?.["start_date"],
      dayjs(resp?.data?.start_time, "HH:mm"),
      resp?.data?.["repeat"],
      resp?.data?.["week_days"],
      resp?.data?.["day_of_month"],
      resp?.data?.["month_option"],
      resp?.data?.["month"],
      resp?.data?.["every"],
      setOccurString,
      !resp?.data?.day_of_month ? 2 : 1
    );
    setWoDocuments(resp?.data?.files);
    setMaintanceDetails(data);
    setPlId(data?.production_line);
    setSopData(resp?.data?.sop || {});
    resp?.data?.sop?._id &&
      resp?.data?.sop?.is_inspection &&
      setIsInspection(true);
    setAnswers(resp?.data?.sopSubmission?.responses || {});
    form.setFieldsValue({ ...data, subTypeObj: data?.subTypeObj?._id });
    // form.setFieldsValue({...data,subTypeObj:{
    //   label: resp?.data?.subTypeObj?.name,
    //   value: resp?.data?.subTypeObj?._id
    // }});
  } catch (error) {
    navigate("/maintenances");
  }
};

export const deleteMaintanance = async (params, navigate, type) => {
  try {
    const resp = await deleteMaintananceApi(params?.id, { type: type });
    resp &&
      navigate(
        `/${type == INSPECTION ? "inspections" : "maintenances"}?type=conf`
      );
    message.success(resp?.data?.message);
  } catch (error) {
    message.error(error?.repsonse?.data?.message);
  }
};

export const deleteOccurence = async (params, navigate ,type) => {
  try {
    const resp = await deleteOccurenceApi(params?.id,{type:type});
    resp && navigate(
      `/${type == INSPECTION ? "inspections" : "maintenances"}?type=jobs`
    );
    message.success(resp?.data?.message);
  } catch (error) {
    message.error(error?.repsonse?.data?.message);
  }
}

const formatDateTime = (date, format) =>
  date ? dayjs(date).format(format) : null;

export const submitForm = async (params, values, type) => {
  if (params.id) {
    return params.type
      ? updateOccurenceApi(params.id, {
          assigned_groups: values.assigned_groups,
          assigned_users: values.assigned_users,
        })
      : updateMaintananceApi(params.id, {
          ...values,
          type: type,
          subTypeObj: values?.subTypeObj || "",
        });
  } else {
    return createMaintananceApi({
      ...values,
      sop: values?.sop || undefined,
      type: type,
    });
  }
};

export const prepareValues = (values, typeofRec) => {
  let endDate = "";
  if (values?.start_date && values?.end_date) {
    let startDate = dayjs(values?.start_date);
    endDate = dayjs(values?.end_date)
      .set("hour", startDate.hour())
      .set("minute", startDate.minute())
      .set("second", startDate.second())
      .set("millisecond", startDate.millisecond());
  }
  const cleanedValues = {
    ...values,
    start_date: values?.start_date
      ? convertToUTCFormat(values?.start_date)
      : "",
    end_date: values?.end_date ? convertToUTCFormat(endDate) : "",
    occurrence_date: values?.occurrence_date
      ? convertToUTCFormat(values?.occurrence_date)
      : "",
  };

  delete cleanedValues.asset_image;
  delete cleanedValues.asset_status;
  delete cleanedValues.comments;
  delete cleanedValues.assigned_list;
  delete cleanedValues.type_of_rec;

  if (typeofRec === 2) {
    delete cleanedValues.day_of_month;
  } else {
    delete cleanedValues.week_days;
    delete cleanedValues.month_option;
  }

  if (!cleanedValues.end_date) delete cleanedValues.end_date;
  if (!cleanedValues.start_date) delete cleanedValues.start_date;
  if (!cleanedValues.occurrence_date) delete cleanedValues.occurrence_date;

  if (!cleanedValues.repeat) {
    delete cleanedValues.start_date;
    delete cleanedValues.end_date;
  }

  if (!cleanedValues.subTypeObj) {
    delete cleanedValues.subTypeObj;
  }

  return cleanedValues;
};

export const handleResponse = async (
  resp,
  params,
  form,
  navigate,
  occDate,
  setEdit,
  setFunctions,
  type
) => {
  const {
    setMaintanceDetails,
    setSopData,
    setWorkOrderID,
    setTypeOfRec,
    setWoDocuments,
    setAnswers,
    setOccurString,
    setIsInspection,
    setPlId,
  } = setFunctions;

  if (params.id) {
    message.success(resp?.data?.message);

    if (params.type) {
      getJob(
        form,
        params,
        setMaintanceDetails,
        setSopData,
        setWorkOrderID,
        setAnswers,
        setIsInspection,
        setPlId
      );
    } else {
      getMaintanace(
        navigate,
        form,
        params,
        setTypeOfRec,
        setWoDocuments,
        setMaintanceDetails,
        setSopData,
        setAnswers,
        occDate,
        setOccurString,
        setIsInspection,
        setPlId
      );
    }
    setEdit(false);
  } else {
    message.success(resp?.data?.message);
    navigate(
      `/${type == INSPECTION ? "inspections" : "maintenances"}?type=conf`
    );
  }
};
export const initializeParams = (
  params,
  form,
  navigate,
  setFunctions,
  occDate
) => {
  const {
    setWorkOrderID,
    setMaintanceDetails,
    setSopData,
    setTypeOfRec,
    setWoDocuments,
    setAnswers,
    setOccurString,
    setEdit,
    setIsInspection,
    setPlId,
  } = setFunctions;

  if (params?.id) {
    if (params.type !== "job") {
      setWorkOrderID(params?.id);
    }

    if (params.type === "job") {
      getJob(
        form,
        params,
        setMaintanceDetails,
        setSopData,
        setWorkOrderID,
        setAnswers,
        setIsInspection,
        setPlId
      );
    } else {
      getMaintanace(
        navigate,
        form,
        params,
        setTypeOfRec,
        setWoDocuments,
        setMaintanceDetails,
        setSopData,
        setAnswers,
        occDate,
        setOccurString,
        setIsInspection,
        setPlId
      );
    }
  } else {
    setEdit(true);
  }
};

export const generateInitialScheduleString = (setOccurString, typeofRec) => {
  generateScheduleString(
    dayjs(),
    "",
    "daily",
    null,
    null,
    null,
    null,
    1,
    setOccurString,
    typeofRec
  );
};

export const updateScheduleString = (form, setOccurString, typeofRec) => {
  const values = form.getFieldsValue();
  generateScheduleString(
    values["start_date"],
    values["start_time"],
    values["repeat"],
    values["week_days"],
    values["day_of_month"],
    values["month_option"],
    values["month"],
    values["every"],
    setOccurString,
    typeofRec
  );
};

export function generateScheduleStringForTable(
  startDate,
  startTime,
  recurrenceType,
  dayOfWeek,
  dayOfMonth,
  monthOption,
  month,
  every
) {
  let scheduleString = "Occurs ";
  let dayOfWeekStr = dayOfWeek?.join(", ") || "";
  switch (recurrenceType) {
    case "daily":
      scheduleString += every > 1 ? `every ${every} days` : "every day";
      break;
    case "weekly":
      const weeklyString =
        every > 1
          ? `every ${every} weeks on ${dayOfWeekStr}`
          : `every ${dayOfWeekStr}`;
      scheduleString += weeklyString;
      break;
    case "monthly":
      scheduleString += "";
      if (dayOfMonth) {
        scheduleString +=
          every > 1
            ? `every ${every} months on day ${dayOfMonth}`
            : `	
            every month on day ${dayOfMonth}`;
      } else {
        scheduleString +=
          every > 1
            ? `every ${every} months on ${monthOption} ${dayOfWeekStr}`
            : `every month on ${monthOption} ${dayOfWeekStr}`;
      }
      break;
    case "yearly":
      scheduleString += "every year on ";
      if (dayOfMonth) {
        scheduleString += `day ${dayOfMonth} of ${month}`;
      } else {
        scheduleString += `${monthOption} ${dayOfWeekStr} of ${month}`;
      }
      break;
    default:
      return "Invalid recurrence type";
  }
  if (startDate) {
    scheduleString += ` at ${dayjs(convertUTCToLocalFormat(startDate)).format(
      "hh:mm A"
    )}`;
  }
  return startDate ? scheduleString : "";
}
