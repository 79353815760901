import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { UserContext } from "../../context/UserProvider";
import {
  getOrganisationApi,
  updateOrganisationApi,
} from "../../services/organisation.services";
import { getMetaDataApi } from "../../services/users.services";
import { ONPREMISE, ORGANISATION } from "../../constants/defaultKeys";
import { getCookie } from "../../helpers/localStorage";
import {
  CloseCircleFilled,
  QuestionCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  deleteDocs,
  getAssetsPath,
  renameFile,
  uploadFunc,
} from "../../helpers/utility";
import OrgSettingsHeader from "./OrgSettingsHeader";

const OrgProfileSettings = () => {
  const [form] = Form.useForm();
  const [details, setDetails] = useState({});
  const [updating, setUpdating] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [profilePic, setProfilePic] = useState("");
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [loading, setLoading] = useState(false);

  const getDetils = async () => {
    try {
      const resp = await getOrganisationApi();
      setDetails(resp?.data);
      form.setFieldsValue(resp?.data);
      setProfilePic(resp?.data?.profile_pic);
    } catch (error) {}
  };

  const onFinish = async (values) => {
    try {
      const resp = await updateOrganisationApi({
        ...values,
        profile_pic: profilePic
          ? storageType == ONPREMISE
            ? profilePic
            : renameFile(profilePic)
          : "",
      });
      if (resp) {
        await getUserData();
        await getDetils();
        message.success(resp?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    getDetils();
  }, []);

  const getUserData = async () => {
    try {
      let resp = await getMetaDataApi();
      const orgId = getCookie(ORGANISATION);
      const activeOrg = orgId
        ? resp?.data?.organizations?.filter(
            (each) => each?.organization?._id == orgId
          )
        : {};
      setUserDetails((prev) => ({
        ...prev,
        activeOrg: activeOrg[0],
        organizations: resp?.data?.organizations,
      }));
    } catch (error) {}
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  async function uploadLogoFunc(info) {
    setLoading(true);
    if (info.file.status === "done") {
      try {
        const resp = await uploadFunc(false, info, storageType);
        setProfilePic(resp?.path);
        setUpdating(true);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }
  }

  const deleteOrgLogo = async () => {
    try {
      deleteDocs(false, profilePic, storageType);
      setProfilePic("");
      form.submit();
    } catch (error) {}
  };

  return (
    <CustomLayout
      header={<OrgSettingsHeader />}
      footer={
        updating && (
          <Flex justify="flex-end" gap={12}>
            <Button
              className={btnStyles.mdBtn}
              onClick={() => {
                form.resetFields();
                setUpdating(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={btnStyles.mdBtn}
              type="primary"
              onClick={form.submit}
            >
              Update
            </Button>
          </Flex>
        )
      }
    >
      <Row gutter={12}>
        <Col span={16}>
          <Space
            direction="vertical"
            style={{ width: "100%", justifyContent: "center", gap: 20 }}
          >
            <Card
              title="Update organisation profile"
              styles={{
                header: { backgroundColor: "#fff" },
              }}
            >
              <Form
                form={form}
                onFinish={onFinish}
                initialValues={details}
                onValuesChange={() => setUpdating(true)}
                requiredMark={false}
                layout="vertical"
                disabled={userDetails?.activeOrg?.role != "admin"}
                style={{
                  pointerEvents:
                    userDetails?.activeOrg?.role == "admin" ? "auto" : "none",
                }}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Enter organisation name",
                    },
                    {
                      whitespace: true,
                      message:
                        "Organisation name cannot be empty or contain only spaces",
                    },
                    {
                      min: 3,
                      message:
                        "Organisation name should contain atleast 3 characters",
                    },
                    {
                      max: 50,
                      message: "Organisation name cannot exceed 50 characters",
                    },
                  ]}
                  name={"name"}
                  label="Organisation name"
                >
                  <Input placeholder="Enter organisation name" />
                </Form.Item>
                <Typography
                  className={`flex-start`}
                  style={{ fontWeight: 500, marginBlock: "12px", gap: "6px" }}
                >
                  Domain :{" "}
                  <span className={typoStyles.body3}>
                    {details?.domain ? details?.domain : "-"}
                  </span>
                  <Tooltip
                    title={
                      <div style={{ padding: "6px" }}>
                        <Typography
                          style={{ fontWeight: "600", color: "#fff" }}
                        >
                          Cannot edit the domain!
                        </Typography>
                        <Typography style={{ color: "#fff" }}>
                          Please connect with support team to edit your domain
                          or any related query.
                        </Typography>
                      </div>
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography>
                <div>
                  <Typography>Organisation Logo</Typography>
                  <div
                    className="flex-start"
                    style={{ marginBlock: "24px", gap: "24px" }}
                  >
                    {profilePic &&
                      (loading ? (
                        <Spin />
                      ) : (
                        <div
                          style={{ position: "relative", minWidth: "100px" }}
                        >
                          <Image
                            src={getAssetsPath(profilePic, storageType)}
                            alt="Logo"
                            style={{ height: "150px", width: "auto" }}
                          />
                          {userDetails?.activeOrg?.role == "admin" && (
                            <div
                              style={{
                                height: "25px",
                                width: "25px",
                                position: "absolute",
                                right: 0,
                                top: -5,
                                cursor: "pointer",
                                backgroundColor: "#667085",
                                borderRadius: "100%",
                              }}
                              className="flex-center"
                              onClick={deleteOrgLogo}
                            >
                              <CloseCircleFilled style={{ fontSize: "24px" }} />
                            </div>
                          )}
                        </div>
                      ))}
                    {userDetails?.activeOrg?.role == "admin" && (
                      <Upload
                        listType="picture"
                        onChange={(info) => uploadLogoFunc(info)}
                        multiple={false}
                        showUploadList={false}
                        customRequest={dummyRequest}
                        accept="image/*"
                      >
                        <Button
                          loading={loading}
                          className={btnStyles.mdBtn}
                          type="primary"
                        >
                          <UploadOutlined />
                          {profilePic ? "Re-upload" : "Upload"}
                        </Button>
                      </Upload>
                    )}
                  </div>
                </div>
              </Form>
            </Card>
          </Space>
        </Col>
      </Row>
    </CustomLayout>
  );
};

export default OrgProfileSettings;
