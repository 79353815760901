import {
  Button,
  Card,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useState } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import {
  convertUTCToLocalFormat,
  tableComponents,
  workOrderstatusList,
} from "../../../helpers/utility";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import {
  ArrowRightOutlined,
  CheckOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { updateMroApi } from "../../../services/work-orders.services";
import dayjs from "dayjs";

const MroWorkOrders = ({ mroDetails, fetchMroDetails }) => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const updateMro = async (review_status) => {
    let payload = { need_verification: review_status };
    try {
      const resp = await updateMroApi(mroDetails?._id, payload);
      if (resp) {
        message.success(resp?.data?.message);
        fetchMroDetails();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const tableColumns = [
    {
      title: "WO code",
      dataIndex: "code",
      key: "code",
      render: (val, record) =>
        val ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() =>
              navigate(`/work-orders/${record?._id}`, {
                state: { source: `/mro/${mroDetails?._id}` },
              })
            }
          >
            {val}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: "WO status",
      dataIndex: "status",
      key: "status",
      render: (val, record) => {
        const statusObj = workOrderstatusList?.filter(
          (s) => s.value == val
        )?.[0];
        return (
          <>
            {statusObj?.label ? (
              <Tag
                className={tagStyles.smTag}
                color={statusObj?.color}
                style={{ marginBottom: 4 }}
              >
                {statusObj?.label}
              </Tag>
            ) : (
              "-"
            )}
            {statusObj?.label === "Closed" && (
              <>
                <Typography style={{ fontSize: 13 }}>
                  {record?.ended_at
                    ? dayjs(convertUTCToLocalFormat(record?.ended_at)).format(
                        "DD MMM YYYY"
                      )
                    : ""}
                </Typography>
                <Typography style={{ fontSize: 13 }}>
                  {record?.ended_at
                    ? dayjs(convertUTCToLocalFormat(record?.ended_at)).format(
                        "hh:mm A"
                      )
                    : ""}
                </Typography>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "WO verification",
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val ? "success" : "error"}
          style={{ border: "0px" }}
        >
          {val ? "Verified" : "Not verified"}
        </Tag>
      ),
    },
  ];

  return (
    <Card
      title="MRO Work Orders"
      styles={{
        header: { backgroundColor: "#fff" },
        body: { padding: mroDetails?.workorders?.length > 0 ? "0px" : "24px" },
      }}
    >
      <>
        {mroDetails?.workorders?.length > 0 && (
          <Table
            columns={tableColumns}
            dataSource={mroDetails?.workorders || []}
            pagination={false}
            components={tableComponents}
          />
        )}
        {mroDetails?.workorders?.length === 0 && (
          <>
            {mroDetails?.need_verification === "not required" && (
              <>
                <Button
                  className={btnStyles.smBtn}
                  style={{
                    width: "100%",
                    backgroundColor: "#FFF7F5",
                    borderColor: "#FEE3D7",
                  }}
                  onClick={() => setShowReviewModal(true)}
                >
                  <Space
                    align="center"
                    style={{ width: "100%", justifyContent: "space-between" }}
                  >
                    <Typography style={{ color: "#6E1A1A" }}>
                      Needs Intervention
                    </Typography>
                    <ArrowRightOutlined style={{ color: "#6E1A1A" }} />
                  </Space>
                </Button>
                <Modal
                  title="Review Confirmation"
                  open={showReviewModal}
                  onCancel={() => setShowReviewModal(false)}
                  footer={null}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Typography>
                      Are you sure you want to involve higher management to
                      determine whether this is a mechanical or electrical issue
                      ?
                    </Typography>
                    <Button
                      className={btnStyles.smBtn}
                      type="primary"
                      style={{ float: "right" }}
                      onClick={() => {
                        setShowReviewModal(false);
                        updateMro("need verification");
                      }}
                    >
                      Submit
                    </Button>
                  </Space>
                </Modal>
              </>
            )}
            {mroDetails?.need_verification === "verification sent" && (
              <Button
                className={btnStyles.smBtn}
                style={{
                  width: "100%",
                  backgroundColor: "#FFFDF5",
                  borderColor: "#FEECD7",
                  cursor: "default",
                }}
              >
                <Space
                  align="center"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Typography style={{ color: "#BA7334" }}>
                    <InfoCircleOutlined />
                    <span style={{ marginLeft: 12 }}>
                      Review is in Progress
                    </span>
                  </Typography>
                  <Typography
                    style={{
                      color: "#8A4F1C",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateMro("verified");
                    }}
                  >
                    Done
                  </Typography>
                </Space>
              </Button>
            )}
            {mroDetails?.need_verification === "verified" && (
              <Button
                className={btnStyles.smBtn}
                style={{
                  width: "100%",
                  backgroundColor: "#F7FFF5",
                  borderColor: "#D7FEDB",
                  textAlign: "left",
                  pointerEvents: "none",
                }}
              >
                <Typography style={{ color: "#216E1A" }}>
                  <CheckOutlined />
                  <span style={{ marginLeft: 12 }}>Review is done</span>
                </Typography>
              </Button>
            )}
          </>
        )}
        {mroDetails?.workorders?.length === 0 &&
          ["admin", "manager", "supervisor"].includes(
            userDetails?.activeOrg?.role
          ) && (
            <Button
              className={btnStyles.mdBtn}
              style={{
                width: "100%",
                marginTop: 16,
              }}
              type="primary"
              disabled={
                mroDetails?.need_verification === "verification sent"
                  ? true
                  : false
              }
              onClick={() =>
                navigate(`/work-orders/create`, {
                  state: {
                    mro_id: mroDetails?._id,
                    description: mroDetails?.description,
                    scheduled_start_date: mroDetails?.start_date,
                    mro_type: mroDetails?.typeObj?._id,
                    asset_id: mroDetails?.asset?._id,
                    disableType:
                      mroDetails?.need_verification === "verified"
                        ? true
                        : false,
                  },
                })
              }
            >
              <span style={{ fontSize: 18 }}>+</span> Create Work Order
            </Button>
          )}
      </>
    </Card>
  );
};

export default MroWorkOrders;
