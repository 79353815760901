import { Button, Divider, Form, Image, Input, Space, Typography } from "antd";
import React from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
import { useNavigate } from "react-router-dom";

const EmailLogin = ({ onFinish, setLoginType, loading, orgDetails }) => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          style={{ height: "100px", width: "auto" }}
          preview={false}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          {orgDetails.name ? `Login to ${orgDetails.name}` : "Login"}
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Welcome back! Please enter your details.
        </Typography>
      </div>
      <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item
          label="Email or Mobile"
          name="emailOrMobile"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (
                    /^[0-9]{10}$/.test(value) ||
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    )
                  ) {
                    callback();
                  } else {
                    callback("Please enter a valid email or mobile number");
                  }
                } else {
                  callback("Please enter your email or mobile number");
                }
              },
            },
          ]}
        >
          <Input
            placeholder="Enter your email"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              max: 500,
              message: "Password cannot exceed 500 characters",
            },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
              message:
                "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter your password"
            className={inputStyles.smInput}
            style={{ width: "100%", gap: "0px" }}
          />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => setLoginType("forgot")}
            type="link"
            style={{ marginBottom: "24px", paddingInline: "0px" }}
          >
            Forgot password?
          </Button>
        </div>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            htmlType="submit"
            className={btnStyles.mdBtn}
            type="primary"
            loading={loading}
            style={{ width: "100%" }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center">
        <Divider>Or</Divider>
        <div>
          {(!orgDetails?.name || orgDetails?.face_login) && (
            <Button
              onClick={() => {
                setLoginType("face");
              }}
              type="link"
              style={{
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Login with face recognition
            </Button>
          )}

          <Button
            onClick={() => {
              setLoginType("mobile");
              navigate("/mobile");
            }}
            type="link"
            style={{
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            Login with mobile
          </Button>
        </div>
        {window.location.hostname == process.env.REACT_APP_PUBLIC_DOMAIN && (
          <>
            <br />
            <Space align="baseline" style={{ gap: 0 }}>
              <span style={{ color: "#2f3542", marginRight: "6px" }}>
                Don't have an account?{" "}
              </span>
              <Button
                onClick={() => navigate("/signup/0")}
                type="link"
                style={{ marginBottom: "24px", paddingInline: "0px" }}
              >
                Sign Up
              </Button>
            </Space>
          </>
        )}
      </div>
    </>
  );
};

export default EmailLogin;
