import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Image, message, Typography } from "antd";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";

const FaceLogin = ({
  setLoginType,
  orgDetails,
  onSubmitFace,
  image,
  setImage,
}) => {
  const webcamRef = useRef(null);
  const [webcamError, setWebcamError] = useState(false);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      message.warning("No image captured. Please capture your face first.");
      return;
    }
    onSubmitFace(image);
  };

  const handleWebcamError = (err) => {
    console.log("error  ", err);
    setWebcamError(true);
  };

  return (
    <div className="webcam-container" style={{ textAlign: "center" }}>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          style={{ height: "100px", width: "auto" }}
          preview={false}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          {orgDetails.name ? `Login to ${orgDetails.name}` : "Login"}
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text`}
          style={{ marginBottom: "32px" }}
        >
          Welcome back! Please enroll your face
        </Typography>
      </div>
      {image ? (
        <>
          <Image
            src={image}
            alt="Captured Face"
            preview={false}
            style={{
              borderRadius: "50%",
              width: "250px",
              height: "300px",
              objectFit: "cover",
              marginBottom: "15px",
            }}
          />
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button onClick={() => setImage(null)} className="capture-btn">
              Retake Face
            </Button>

            <Button
              type="primary"
              onClick={handleSubmit}
              className="submit-btn"
            >
              Submit Image
            </Button>
          </div>
        </>
      ) : webcamError ? (
        <div>
          <p>Sorry, we couldn't access your webcam. Please try again later.</p>
          <Button onClick={() => setWebcamError(false)} type="primary">
            Try Again
          </Button>
        </div>
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              facingMode: "user",
            }}
            onUserMediaError={handleWebcamError}
            style={{
              borderRadius: "50%",
              width: "250px",
              height: "300px",
              objectFit: "cover",
              marginBottom: "15px",
            }}
            alt="Webcam"
          />
          <div>
            <Button type="primary" onClick={capture} className="capture-btn">
              Capture Face
            </Button>
          </div>
        </>
      )}
      <div style={{ marginTop: "15px" }}>
        <Button
          onClick={() => {
            setImage(null);
            setWebcamError(false);
            setLoginType("login");
          }}
        >
          <ArrowLeftOutlined /> Back
        </Button>
      </div>
    </div>
  );
};

export default FaceLogin;
