import { Col, Row, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  compareFaceApi,
  getMetaDataApi,
  loginApi,
  sendOtpApi,
  validateOtpApi,
} from "../../../services/users.services";
import { setCookie } from "../../../helpers/localStorage";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATION,
  REFRESH_TOKEN,
} from "../../../constants/defaultKeys";
import OTPScreen from "./OTPScreen";
import MobileScreen from "./MobileScreen";
import EmailLogin from "./EmailLogin";
import ForgotPasword from "./ForgotPassword";
import CheckEmail from "./CheckEmail";
import loginBG from "../../../assets/images/cmms-logos/loginBG.svg";
import ChooseOrgModal from "../../Organisations/ChooseOrgModal";
import { UserContext } from "../../../context/UserProvider";
import {
  getSubdomain,
  redirectToDashboard,
} from "../../../helpers/domainUtility";
import { getDomainDetailsApi } from "../../../services/organisation.services";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
import FaceLogin from "./FaceLogin";
import { image } from "d3";

const Login = ({ pageType }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);

  const [loginType, setLoginType] = useState(pageType || "email");
  const [loginDetails, setLoginDetails] = useState({
    mobile: "",
    token: "",
    email: "",
  });
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState({ logo: darkLogo, name: "" });
  const [forgot, setForgot] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const resp = await loginApi(values);
      if (resp) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );

        await getOrgData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const onSubmitFace = async (image) => {
    setLoading(true);
    if (!image) {
      message.warning("Please capture your face before submitting!");
      return;
    }
    const payload = { image };

    try {
      const resp = await compareFaceApi(payload);
      if (resp?.data.success) {
        setCookie(
          ACCESS_TOKEN,
          resp?.data?.access_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        setCookie(
          REFRESH_TOKEN,
          resp?.data?.refresh_token,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );

        await getOrgData();
      } else {
        message.error(resp?.data?.message);
      }
    } catch (error) {
      console.error("Error during face comparison:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setImage(null);
      setLoading(false);
    }
  };

  const getOrgData = async () => {
    try {
      const resp = await getMetaDataApi();
      if (orgDetails?.name) {
        const activeOrg =
          resp?.data?.organizations?.filter(
            (eachOrg) => eachOrg?.organization?._id === orgDetails?.id
          )?.[0] || {};
        if (Object?.keys(activeOrg).length > 0) {
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else {
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      } else {
        if (resp?.data?.organizations?.length === 1) {
          if (
            !resp?.data?.organizations?.[0]?.organization?.settings
              ?.face_login &&
            loginType === "face"
          ) {
            message.error("Face login is not enabled for this organization");
            return;
          }

          const activeOrg = resp?.data?.organizations?.[0];
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else if (resp?.data?.organizations?.length === 0) {
          navigate("/signup/1");
          setUserDetails(resp?.data);
        } else {
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      }
    } catch (error) {}
  };

  const sendOtp = async (values) => {
    try {
      const resp = await sendOtpApi(values);
      if (resp) {
        message.success("OTP sent successfully");
        setLoginType("otp");
        setLoginDetails({
          mobile: values?.mobile_number,
          token: resp?.data?.token,
          email: "",
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const validateOtp = async (values) => {
    try {
      if (forgot) {
        const resp = await validateOtpApi({
          ...values,
          screen_type: "forgot-password",
          token: loginDetails?.token,
        });
        if (resp) {
          setForgot(false);
          navigate(`/join/${resp?.data?.reset_password_token}`);
        }
      } else {
        const resp = await validateOtpApi({
          ...values,
          screen_type: "login",
          token: loginDetails?.token,
        });
        if (resp) {
          setCookie(
            ACCESS_TOKEN,
            resp?.data?.access_token,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            REFRESH_TOKEN,
            resp?.data?.refresh_token,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          if (resp?.data?.is_first_login) {
            navigate("/set-password");
          } else {
            await getOrgData();
          }
        }
      }
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  const getDomainDetails = async (domain) => {
    try {
      const resp = await getDomainDetailsApi(domain);
      setOrgDetails({
        logo: resp?.data?.org?.profile_pic,
        name: resp?.data?.org?.name,
        id: resp?.data?.org?._id,
        face_login: resp?.data?.org?.settings?.face_login,
      });
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/login`;
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_STORAGE === "CLOUD") {
      const subdomain = getSubdomain(window.location.hostname);
      subdomain &&
        subdomain != process.env.REACT_APP_PUBLIC_DOMAIN &&
        getDomainDetails(subdomain);
    }
  }, []);

  return (
    <Row
      style={{
        height: "100vh",
        alignItems: "center",
        alignSelf: "center",
        backgroundImage: `url(${loginBG})`,
        backgroundSize: "auto 40vw",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center -20vh",
        mixBlendMode: "soft-light",
      }}
    >
      <ChooseOrgModal
        openModal={openChooseModal}
        closable={false}
        loginType={loginType === "face" ? loginType : ""}
      />
      <Col
        xs={{ span: 16, offset: 4 }}
        md={{ span: 10, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        {loginType === "otp" ? (
          <OTPScreen
            loginDetails={loginDetails}
            validateOtp={validateOtp}
            setLoginDetails={setLoginDetails}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
            forgot={forgot}
          />
        ) : loginType === "mobile" ? (
          <MobileScreen
            sendOtp={sendOtp}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
          />
        ) : loginType === "forgot" ? (
          <ForgotPasword
            setForgot={setForgot}
            sendOtp={sendOtp}
            setLoginType={setLoginType}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            orgDetails={orgDetails}
          />
        ) : loginType === "check" ? (
          <CheckEmail
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            setLoginType={setLoginType}
            orgDetails={orgDetails}
          />
        ) : loginType === "face" ? (
          <FaceLogin
            setLoginType={setLoginType}
            orgDetails={orgDetails}
            getOrgData={getOrgData}
            onSubmitFace={onSubmitFace}
            image={image}
            setImage={setImage}
          />
        ) : (
          <EmailLogin
            onFinish={onFinish}
            setLoginType={setLoginType}
            loading={loading}
            orgDetails={orgDetails}
          />
        )}
      </Col>
    </Row>
  );
};

export default Login;
