import {
  Card,
  Col,
  Empty,
  Flex,
  Image,
  Row,
  Select,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import {
  getAssetsPath,
  renameFile,
  uploadFunc,
} from "../../../helpers/utility";
import { getAllAssetsApi } from "../../../services/assets.services";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE } from "../../../constants/defaultKeys";
import { useParams } from "react-router-dom";

const GeneralDetails = ({
  edit,
  form,
  sopDetails,
  documents,
  setDocuments,
  setEnableAI,
  sopMetaData,
  setsopMetaData,
}) => {
  const [assets, setAssets] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });
  const [fileLists, setFileLists] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const params = useParams();
  const dummyhandleChange = (value, e) => {
    setEnableAI && setEnableAI(true);
    setsopMetaData((prev) => ({ ...prev, asset: value }));
    if (e?.image) {
      setImage({ name: e?.image, status: e?.status });
    }
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {}
  };
  const uploadImage = async (info, key) => {
    const resp = await uploadFunc(false, info);
    if (resp) {
      await form?.setFieldValue("files", [...(documents || []), resp?.path]);
      setDocuments([...(documents || []), resp?.path]);
    }
  };

  const createFileLists = (documents) => {
    const fileList = documents?.map((doc, index) => {
      return {
        uid: index,
        name: doc,
        status: "done",
        url: getAssetsPath(doc, storageType),
      };
    });
    return fileList;
  };

  useEffect(() => {
    setImage({
      name: form?.getFieldValue("asset_image") || sopDetails?.asset?.file,
      status: form?.getFieldValue("asset_status") || sopDetails?.asset?.status,
    });
  }, [form, sopDetails]);

  useEffect(() => {
    getAssets();
  }, []);

  useEffect(() => {
    const newFileLists = createFileLists(documents);
    setFileLists(newFileLists);
    if (documents?.length > 0 && setEnableAI) {
      setEnableAI(true);
    }
  }, [documents]);

  return (
    <Card
      title="General details"
      style={{ marginLeft: "48px" }}
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row>
        <Col span={16}>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Select
              disabled={params?.id}
              placeholder="Select asset"
              style={{
                width: "100%",
                marginBottom: 12,
              }}
              value={sopMetaData?.asset}
              filterOption={(inputValue, option) =>
                option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              className={inputStyles.smSelect}
              onChange={dummyhandleChange}
              options={assets}
            />
          </Flex>
          <>
            <Flex
              vertical
              gap={12}
              style={{
                backgroundColor: "rgb(235 235 235)",
                padding: "12px 24px",
                borderRadius: "8px",
              }}
            >
              <Typography>
                SOP is created using the following information for this Asset:
              </Typography>
              <Flex style={{ gap: "6px" }}>
                <Flex
                  justify="flex-start"
                  gap={6}
                  style={{
                    marginInlineStart: "12px",
                    flexDirection: "column",
                  }}
                >
                  {[
                    {
                      title: "Make",
                      value: sopDetails?.asset?.make || "-",
                    },
                    {
                      title: "Model",
                      value: sopDetails?.asset?.model || "-",
                    },
                  ].map((each) => {
                    return (
                      <Typography style={{ width: "100%", display: "flex" }}>
                        <span style={{ width: "50px" }}>{each.title}: </span>
                        <span style={{ fontWeight: 500, color: "#3D5A80" }}>
                          {each.value ?? "-"}
                        </span>{" "}
                      </Typography>
                    );
                  })}
                </Flex>

                <div style={{ marginInlineStart: "12px" }}>
                  <span>Documents:</span>

                  <Flex style={{ gap: "6px" }}>
                    {fileLists?.length > 0 ? (
                      <Flex style={{ gap: "6px", flexDirection: "column" }}>
                        {fileLists?.map((eachFile) => (
                          <a
                            href={eachFile.url}
                            target="_blank"
                            style={{ color: "#101828" }}
                          >
                            <div
                              className={"fileListItem"}
                              style={{
                                width: "fit-content",
                                marginTop: "0px",
                              }}
                            >
                              <span style={{ fontSize: "12px" }}>
                                {storageType == ONPREMISE
                                  ? eachFile?.name?.slice(0, 50)
                                  : renameFile(eachFile?.name)?.slice(0, 50)}
                              </span>
                            </div>
                          </a>
                        ))}
                      </Flex>
                    ) : (
                      "-"
                    )}
                  </Flex>
                </div>
              </Flex>
              <br />
            </Flex>
          </>
        </Col>
        <Col span={8} style={{}}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.name ? (
              <Image
                src={getAssetsPath(image.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
