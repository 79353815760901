import { Button, Form, Image, Input, Typography, message } from "antd";
import React, { useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { reSendOtpApi } from "../../../services/users.services";
import { ArrowLeftOutlined } from "@ant-design/icons";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";

const OTPScreen = ({
  loginDetails,
  validateOtp,
  setLoginDetails,
  setLoginType,
  orgDetails,
  forgot,
}) => {
  console.log("loginDetails", loginDetails);
  console.log("forgot", forgot);
  const [isResend, setIsresend] = useState();
  const [count, setCount] = useState(0);

  const resendOtp = async () => {
    try {
      const resp = await reSendOtpApi({ token: loginDetails?.token });
      if (resp) {
        setIsresend(true);
        setCount(count + 1);
        message.success(resp?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          style={{ height: "100px", width: "auto" }}
          preview={false}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          Enter OTP sent to {loginDetails?.mobile}
        </Typography>
      </div>

      <Form layout="vertical" onFinish={validateOtp} requiredMark={false}>
        <Form.Item
          label="OTP"
          name={"otp"}
          rules={[
            {
              required: true,
              message: "Enter OTP",
            },
            {
              pattern: /^[0-9]{4}$/,
              message: "Please enter a valid 4-digit OTP",
            },
          ]}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter OTP"
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Verify OTP
          </Button>
        </Form.Item>
      </Form>
      {count > 3 && (
        <Typography className="text-center">
          Please try again after sometime!!
        </Typography>
      )}
      {count < 4 && (
        <Typography className="flex-center" style={{ gap: "4px" }}>
          Haven't received OTP yet?
          <Button
            style={{ margin: "0px", paddingInline: "0px", fontWeight: "500" }}
            type="link"
            onClick={resendOtp}
          >
            Resend OTP
          </Button>
        </Typography>
      )}
      <div className="text-center">
        <Button
          onClick={() => {
            setLoginDetails({ mobile: "", token: "", email: "" });
            setLoginType("email");
          }}
          type="link"
          style={{ fontSize: "16px", lineHeight: "24px" }}
        >
          <ArrowLeftOutlined /> Back to log in
        </Button>
      </div>
    </>
  );
};

export default OTPScreen;
