import { Button, Form, Image, Input, Typography, message } from "antd";
import React, { useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { forgotPasswordApi } from "../../../services/users.services";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
// import { useNavigate } from "react-router-dom";

const ForgotPasword = ({
  setLoginType,
  setLoginDetails,
  loginDetails,
  orgDetails,
  setForgot,
  sendOtp,
}) => {
  const [loading, setLoading] = useState(false);
  const forgotPassword = async (values) => {
    setLoading(true);
    const { text } = values;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[6-9]\d{9}$/;
    let resetType = "email";
    if (emailRegex.test(text)) {
      resetType = "email";
    } else if (mobileRegex.test(text)) {
      resetType = "mobile";
    } else {
      console.log("Invalid input");
    }

    try {
      if (resetType === "mobile") {
        setForgot(true);
        sendOtp({ mobile_number: text });
      } else {
        const resp = await forgotPasswordApi({
          email: values?.text,
        });
        if (resp) {
          setLoginDetails({ ...loginDetails, email: values?.text });
          setLoginType("check");
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <Image
          src={orgDetails.logo || darkLogo}
          preview={false}
          style={{ height: "100px", width: "auto" }}
        />
        <Typography style={{ marginBlock: "12px" }} className={typoStyles.h6}>
          Forgot password?
        </Typography>
        <Typography
          className={`${typoStyles.body3} grey-text `}
          style={{ marginBottom: "32px" }}
        >
          No worries, we’ll send you reset instructions.
        </Typography>
      </div>

      <Form layout="vertical" onFinish={forgotPassword} requiredMark={false}>
        <Form.Item
          // label="Email"
          // name="email"
          className="forgot-password"
          label="Email or Mobile Number"
          name="text"
          rules={[
            // { type: "email", message: "Please enter a valid email" },
            {
              required: true,
              // message: "Enter your email",
              message: "Enter your email or mobile number",
            },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.resolve();
                }
                const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                const isMobile = /^[6-9]\d{9}$/.test(value);
                if (isEmail || isMobile) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid email or mobile number")
                );
              },
            },
          ]}
        >
          <Input
            // placeholder="Enter your email"
            placeholder="Enter your email or mobile number"
            className={inputStyles.smInput}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center">
        <Button
          onClick={() => {
            setLoginDetails({ mobile: "", token: "", email: "" });
            setLoginType("email");
          }}
          type="link"
          style={{ fontSize: "16px", lineHeight: "24px" }}
        >
          <ArrowLeftOutlined /> Back to log in
        </Button>
      </div>
    </>
  );
};

export default ForgotPasword;
