import {
  Button,
  Flex,
  Form,
  Input,
  Select,
  Typography,
  message,
  Card,
  Modal,
  Image,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import {
  getMetaDataApi,
  updateProfileApi,
  updateUserApi,
} from "../../../services/users.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

import {
  deleteDocs,
  renameFile,
  uploadFunc,
  userRoles,
} from "../../../helpers/utility";
import { getCookie, setCookie } from "../../../helpers/localStorage";
import { FACILITYID, ORGANISATION } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import { CloseCircleFilled, SignatureOutlined } from "@ant-design/icons";
import DigitalSignatureDraw from "./DigitalSignature";
// import FaceLogin from "./FaceLoginSetting";
const Profile = () => {
  const [currUser, setCurrUser] = useState({});
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [result, setResult] = useState(null);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setCurrUser(userDetails);
    setResult(userDetails?.digital_sign_img);
    form.setFieldsValue(userDetails);
  }, [userDetails]);

  const onFinish = async (values) => {
    if (!values.email && !values.mobile_number) {
      message.error("Please enter either email or phone number");
    } else {
      const updatedUser = {
        ...values,
        digital_sign_img: values?.digital_sign_img
          ? renameFile(values?.digital_sign_img)
          : "",
        ...(values?.activeOrg || {}),
      };
      if (userDetails?.digital_sign_img) {
        delete updatedUser?.digital_sign_img;
      }
      delete updatedUser?.activeOrg;
      delete updatedUser?.role;
      delete updatedUser?.designation;
      delete updatedUser?.employee_id;
      try {
        const resp = await updateProfileApi(updatedUser);
        if (resp) {
          fetchUserData();
          message.success(resp?.data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      } finally {
        setUpdating(false);
      }
    }
  };

  const clearResult = () => {
    form.setFieldsValue({ digital_sign_img: "" });
    deleteDocs(true, result, "cloud");
    setResult(null);
  };

  const fetchUserData = async () => {
    try {
      const orgID = getCookie(ORGANISATION);
      const id = getCookie(FACILITYID);
      const resp = await getMetaDataApi();
      if (resp) {
        setUserDetails((prev) => ({
          ...prev,
          ...resp?.data,
          activeOrg: orgID
            ? resp?.data?.organizations?.filter(
                (each) => each?.organization?._id == orgID
              )?.[0]
            : {},
        }));
        setCurrUser(resp?.data);
        form.setFieldsValue(resp?.data);
        !id &&
          setCookie(
            FACILITYID,
            "all",
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  };

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>My Profile</Typography>}
      footer={
        updating && (
          <Flex justify="flex-end" gap={12}>
            <Button
              className={btnStyles.mdBtn}
              onClick={() => {
                setResult(userDetails?.d_signature?.image);
                form.resetFields();
                setUpdating(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={btnStyles.mdBtn}
              type="primary"
              onClick={form.submit}
            >
              Update
            </Button>
          </Flex>
        )
      }
    >
      <Card
        title="Update profile"
        styles={{
          header: { backgroundColor: "#fff" },
        }}
        style={{ width: "40vw", margin: "auto" }}
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
          onFieldsChange={(e) => setUpdating(true)}
          initialValues={currUser}
        >
          <Form.Item
            label="First name"
            name={"first_name"}
            rules={[{ required: true, message: "Please enter first name" }]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter first name"
            />
          </Form.Item>
          <Form.Item label="Last name" name={"last_name"}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter last name"
            />
          </Form.Item>
          <Form.Item
            label="Email"
            name={"email"}
            rules={[{ type: "email", message: "Please enter a valid email" }]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter email"
            />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name={"mobile_number"}
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter phone number"
            />
          </Form.Item>
          {/* <Form.Item label="Employee ID" name={["activeOrg", "employee_id"]}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter employee ID"
              disabled
            />
          </Form.Item>
          <Form.Item label="Designation" name={["activeOrg", "designation"]}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter designation"
              disabled
            />
          </Form.Item> */}
          <Form.Item label="Role" name={["activeOrg", "role"]}>
            <Select
              disabled={userDetails?.activeOrg?.role !== "admin"}
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              options={userRoles}
              placeholder="Select role"
            />
          </Form.Item>
          {(userDetails?.activeOrg?.role === "admin" ||
            userDetails?.activeOrg?.role === "manager") && (
            <Form.Item label="Digital Signature" name="digital_sign_img">
              {result ? (
                <div style={{ position: "relative", width: "354px" }}>
                  <Image
                    src={result}
                    alt="Captured"
                    style={{ height: "150px", width: "354px" }}
                  />
                  {!userDetails?.digital_sign_img && (
                    <div
                      style={{
                        height: "25px",
                        width: "25px",
                        position: "absolute",
                        cursor: "pointer",
                        backgroundColor: "#667085",
                        borderRadius: "100%",
                        right: 0,
                        top: 0,
                      }}
                      className="flex-center"
                      onClick={clearResult}
                    >
                      <CloseCircleFilled style={{ fontSize: "24px" }} />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Button
                    type="primary"
                    style={{ marginTop: "5px" }}
                    onClick={showModal}
                  >
                    Add Signature <SignatureOutlined />
                  </Button>
                </>
              )}
            </Form.Item>
          )}
          <Modal
            title="Add Signature"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
          >
            <DigitalSignatureDraw
              result={result}
              setResult={setResult}
              setIsModalOpen={setIsModalOpen}
              setUpdating={setUpdating}
              form={form}
            />
          </Modal>
        </Form>
      </Card>
      {/* <FaceLogin /> */}
    </CustomLayout>
  );
};

export default Profile;
