import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Empty,
  Image,
  Input,
  Pagination,
  Popover,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  bulkGenerateQRCodes,
  getAllAssetsForListViewApi,
  getAllAssetsWithLocsApi,
  getAssetCategories,
  getAssetQRCodes,
} from "../../services/assets.services";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGESIZE, createAsset } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  debounce,
  displayFilterTag,
  getAssetsPath,
  getSearchDefaultValue,
  minutesToHoursAndMinutes,
  scrollToTopTable,
  statusOptions,
  tableComponents,
} from "../../helpers/utility";
import { SearchOutlined } from "@ant-design/icons";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import AssetFilters from "./AssetFilters";
import ImportAsset from "./ImportAsset";
import { getItem, removeItem, setItem } from "../../helpers/localStorage";

const AssetsTableView = ({ setAssetsLength, viewType }) => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [assets, setAssets] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [assetCategories, setAssetCategories] = useState([]);
  const [filters, setFilters] = useState({
    categories: [],
    statuses: [],
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [locations, setLocations] = useState({});

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const { page, searchVal, pageType } = location.state || {};

  function transformData(data) {
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      return {
        value: node._id,
        title: node.name,
        disabled: node?.disabled,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }
    let treeData = transformNode(data);
    return treeData;
  }

  const fetchLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      let treeData = transformData(resp?.data?.locations?.[0]);

      setLocations(treeData);
    } catch (error) {
      setLocations({});
    }
  };

  const fetchData = async (data, clearLocStorage) => {
    let query = { page: data?.pageNumber };
    if (data?.search) {
      query = { ...query, name: data?.search };
    }
    data?.filters?.categories?.length > 0 &&
      (query.groups = data?.filters?.categories);
    data?.filters?.statuses?.length > 0 &&
      (query.status = data?.filters?.statuses);

    setLoading(true);
    try {
      const res = await getAllAssetsForListViewApi(query);
      setAssets(res?.data);
      setAssetsLength(res?.data?.totalAssets || 0);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setAssets(null);
      setAssetsLength(0);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  const fetchAssetCategories = async () => {
    try {
      const res = await getAssetCategories();
      setAssetCategories(
        res?.data?.map((c) => ({
          title: c?.name,
          value: c?._id,
        }))
      );
    } catch (error) {
      setAssetCategories([]);
    }
  };

  useEffect(() => {
    fetchAssetCategories();
    fetchLocations();

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setFilters({
        categories: locStorageData?.filters?.categories || [],
        statuses: locStorageData?.filters?.statuses || [],
      });
      locStorageData?.search && setSearch(locStorageData?.search);
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  useEffect(() => {
    const fetchDetails = async (data, status = false) => {
      await fetchData(data, status);
      scrollToTopTable(tableRef);
    };

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      fetchDetails(locStorageData, true);
    } else {
      fetchDetails({
        filters: filters,
        search: search,
        pageNumber: pageNumber,
      });
    }
    // fetchDetails();
  }, [pageNumber, refresh]);

  // useEffect(() => {
  //   if (pageNumber === 1) {
  //     setRefresh((prev) => !prev);
  //   } else {
  //     setPageNumber(1);
  //   }
  // }, [filters]);

  // useEffect(() => {
  //   if (pageType === "table") {
  //     page && page > 1 && setPageNumber(page);
  //     if (searchVal && searchVal !== "") {
  //       setSearch(searchVal);
  //       refreshPage();
  //     }
  //     if (page || searchVal) {
  //       navigate(`/assets?type=table`, { state: null });
  //     }
  //   }
  // }, [location.state]);

  // const refreshPage = (source = "") => {
  //   if (source === "search" && pageNumber > 1) {
  //     setPageNumber(1);
  //   } else {
  //     setRefresh((prev) => !prev);
  //   }
  // };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 140,
    },
    {
      title: "Asset",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Image",
      dataIndex: "file",
      key: "file",
      width: 150,
      render: (val) => {
        return val ? (
          <div
            style={{ width: "fit-content" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Image
              src={getAssetsPath(val, storageType)}
              preview={true}
              style={{ height: "80px", width: "80px", borderRadius: 8 }}
            />
          </div>
        ) : (
          <Typography style={{ textAlign: "center" }}>-</Typography>
        );
      },
    },
    {
      title: "Categories",
      dataIndex: "groups",
      key: "groups",
      width: 220,
      render: (val, record) => {
        return (
          <>
            {record?.groups?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                  width: "",
                }}
              >
                {record?.groups?.slice(0, 2)?.map((group) => (
                <Tag
                className={tagStyles.mdTag}
                color="blue"
                style={{
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "left",
                  display: "inline-block", 
                }}
              >
                {group?.name}
              </Tag>
              
               
                ))}
                {record?.groups?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{ flexDirection: "column", gap: "6px" }}
                      >
                        {record?.groups?.map((group) => (
                          <Tag className={tagStyles.mdTag} color="blue">
                            {group?.name}
                          </Tag>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{record?.groups?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: "Asset status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => {
        const color = statusOptions?.filter((each) => each?.value == val)?.[0]
          ?.color;
        return (
          <>
            {val ? (
              <Tag color={color} className={tagStyles.mdTag}>
                {val}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (val) => <>{val?.name}</>,
      width: 200,
    },
  ];

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
    // if (
    //   e?.target?.value?.trim()?.length === 0 ||
    //   e?.target?.value?.trim()?.length > 1
    // ) {
    //   refreshPage("search");
    // }
  };

  const handleQRCodesDownload = async () => {
    try {
      const res = await bulkGenerateQRCodes();
      if (res) {
        const resp = await getAssetQRCodes();
        if (resp?.data) {
          const a = document.createElement("a");
          a.href = resp?.data?.file_url;
          a.setAttribute("download", "assets_qr_codes.pdf");
          a.setAttribute("target", "_blank");
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          message.error("Failed to download all Asset QR codes");
        }
      }
    } catch (error) {
      message.error("Failed to download all Asset QR codes");
    }
  };

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [search, filters]);

  const saveAssetFilters = () => {
    let dataObj = {
      filters: filters,
      search: search,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  return (
    <Row gutter={[0, 16]}>
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <Button
            style={{ color: "#6941C6" }}
            className={btnStyles.smBtn}
            type="default"
            icon={
              <Image
                src={filterActiveIcon}
                width={18}
                height={18}
                alt="filter"
                preview={false}
              />
            }
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </Button>
        </Space>
        {showFilterModal && (
          <AssetFilters
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            assetCategories={assetCategories}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </Col>
      <Col offset={4} span={10} style={{ textAlign: "right" }}>
        <Space style={{ gap: 12 }}>
          {["admin", "manager"].includes(userDetails?.activeOrg?.role) && (
            <>
              <Button
                type="primary"
                className={btnStyles.smBtn}
                onClick={() => setShowImportModal(true)}
              >
                + Import Assets
              </Button>
              {showImportModal && (
                <ImportAsset
                  locations={locations}
                  showImportModal={showImportModal}
                  setShowImportModal={setShowImportModal}
                  reloadPage={() => {
                    setRefresh((prev) => !prev);
                    setFilters({
                      categories: [],
                      statuses: [],
                    });
                    setSearch("");
                  }}
                />
              )}
            </>
          )}
          <Button
            type="primary"
            className={btnStyles.smBtn}
            disabled={false}
            onClick={() => handleQRCodesDownload()}
          >
            Download All QR Codes
          </Button>
        </Space>
      </Col>
      {(filters?.categories?.length > 0 || filters?.statuses?.length > 0) && (
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            flexWrap: "wrap",
          }}
        >
          {filters?.categories?.length > 0 &&
            displayFilterTag(
              "Asset Category",
              "purple",
              filters?.categories?.length
            )}
          {filters?.statuses?.length > 0 &&
            displayFilterTag("Asset Status", "blue", filters?.statuses?.length)}
          <Typography
            style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
            onClick={() =>
              setFilters({
                categories: [],
                statuses: [],
              })
            }
          >
            Clear All
          </Typography>
        </Col>
      )}
      <Col span={24}>
        <Table
          ref={tableRef}
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                defaultValue={getSearchDefaultValue()}
                // defaultValue={searchVal ? searchVal : ""}
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by asset name or code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              {createAsset?.includes(userDetails?.activeOrg?.role) && (
                <Button
                  id="create-asset"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => navigate("/assets/create")}
                >
                  + Create Asset
                </Button>
              )}
            </Space>
          )}
          bordered
          pagination={false}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No assets found</Typography>}
              />
            ),
          }}
          scroll={{
            x: 1200,
            y: "55vh",
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                // navigate(`/assets/${item?._id}?type=table`, {
                //   state: {
                //     pageType: "table",
                //     page: pageNumber,
                //     searchVal: search,
                //   },
                // });
                navigate(`/assets/${item?._id}`, {
                  state: { source: "/assets?type=table" },
                });
                saveAssetFilters();
              },
              style: { cursor: "pointer" },
            };
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={columns}
          dataSource={assets?.assetsData}
          components={tableComponents}
        />
      </Col>
      {assets?.totalAssets > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={assets?.totalAssets}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default AssetsTableView;
