import { Card, Modal, Button, message, Image, Col, Row } from "antd";
import React, { useContext, useState } from "react";
import { SignatureOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserProvider";
import { useParams } from "react-router-dom";
import { updateWorkOrderApi } from "../../../services/work-orders.services";
import dayjs from "dayjs";
import { convertUTCToLocalFormat } from "../../../helpers/utility";

const UploadSignature = ({ userDetails }) => {
  return (
    <div>
      {userDetails?.digital_sign_img ? (
        <Image
          src={userDetails?.digital_sign_img}
          alt="Captured"
          style={{ width: "250px", height: "100px", marginTop: "10px" }}
        />
      ) : (
        <div style={{ textAlign: "center" }}>
          <p>Please upload your digital signature in profile settings.</p>
        </div>
      )}
    </div>
  );
};

const DigitalSignatureCard = ({ workOrderDetails, fetchWODetails }) => {
  const params = useParams();
  const { userDetails } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [result, setResult] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (userDetails?.digital_sign_img) {
      try {
        const payload = {
          qc_verifier_id: userDetails?._id,
        };
        const res = await updateWorkOrderApi(params.id, payload);
        if (res?.data) {
          message.success("Signature Applied Successfully");
          fetchWODetails();
        }
      } catch (error) {
        console.log(error);
        message.error("Failed to apply signature");
      }
    }

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Card title="QC Verification" style={{ marginTop: "12px" }}>
      {workOrderDetails?.qc_verified_by ? (
        <Row gutter={[12, 6]}>
          <Col span={12}>QC Verified by</Col>
          <Col span={12}>
            {workOrderDetails?.qc_verified_by?.first_name ||
            workOrderDetails?.qc_verified_by?.last_name
              ? `${workOrderDetails?.qc_verified_by?.first_name} ${
                  workOrderDetails?.qc_verified_by?.last_name || ""
                }`
              : "-"}
          </Col>
          <Col span={12}>QC Verified at</Col>
          <Col span={12}>
            {" "}
            {workOrderDetails?.qc_verified_at
              ? dayjs(
                  convertUTCToLocalFormat(workOrderDetails?.qc_verified_at)
                ).format("DD MMM YYYY hh:mm A")
              : "-"}{" "}
          </Col>
          <Col span={24} style={{ textAlign: "center", marginTop: "5px" }}>
            {" "}
            <Image
              src={workOrderDetails?.qc_verified_by?.digital_sign_img}
              alt="signature"
              style={{ width: "250px", height: "100px" }}
            />
          </Col>
        </Row>
      ) : (
        <div style={{ textAlign: "center" }}>
          {(userDetails?.activeOrg?.role === "admin" ||
          userDetails?.activeOrg?.role === "manager" )? (
            <Button type="primary" onClick={showModal}>
              Apply Signature <SignatureOutlined />
            </Button>
          ) : (
            <p>No signature attached</p>
          )}
        </div>
      )}

      <Modal
        title="Apply Signature"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          userDetails?.digital_sign_img
            ? [
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,

                <Button key="submit" type="primary" onClick={handleOk}>
                  Confirm
                </Button>,
              ]
            : null
        }
      >
        <UploadSignature userDetails={userDetails} />
      </Modal>
    </Card>
  );
};

export default DigitalSignatureCard;
