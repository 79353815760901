import { Card, Col, Row, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import AuditLogs from "../AuditLogs/AuditLogTable";
import { useNavigate, useParams } from "react-router-dom";
import { getAssetAuditLogsApi } from "../../../services/assets.services";
import {
  convertUTCToLocalFormat,
  tableComponents,
  workOrderstatusList,
} from "../../../helpers/utility";
import dayjs from "dayjs";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { setItem } from "../../../helpers/localStorage";

const AuditLog = ({ assetDetails }) => {
  const navigate = useNavigate();
  const [auditLogData, setAuditLogData] = useState(null);
  // const params = useParams();

  const showCount = 4;

  const fetchAssetAuditLogs = async () => {
    let query = { asset: assetDetails?._id, page: 1 };
    try {
      const res = await getAssetAuditLogsApi(query);
      setAuditLogData(res?.data);
    } catch (error) {
      setAuditLogData(null);
    }
  };

  useEffect(() => {
    if (assetDetails?._id) {
      fetchAssetAuditLogs();
    }
  }, [assetDetails]);

  const tableColumns = [
    {
      title: "Task code",
      dataIndex: "workorder",
      key: "workorder",
      // width: 150,
      render: (_, record) => {
        let taskId = record?.workorder?._id || record?.occurrence?._id || "";
        let taskCode =
          record?.workorder?.code || record?.occurrence?.code || "";
        let taskType = taskCode?.includes("WO")
          ? "work-orders"
          : taskCode?.includes("INS")
          ? "inspections"
          : "maintenances";
        if (taskId) {
          return (
            <Typography
              style={{ color: "#1677ff", cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/${taskType}/${taskId}${
                    taskType === "work-orders" ? "" : "/job"
                  }`,
                  { state: { source: `/assets/${assetDetails?._id}` } }
                );
              }}
            >
              {taskCode}
            </Typography>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      // width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("DD MMM YYYY")
          : "-";
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      // width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("hh:mm A")
          : "-";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // width: 160,
      render: (text, record) => {
        const status = record?.workorder?.status || record?.occurrence?.status;
        const value = workOrderstatusList.find(
          (statusItem) => statusItem.value === status
        );
        return value ? (
          <Tag
            className={tagStyles.smTag}
            style={{ fontWeight: "600", minWidth: "80px", textAlign: "left" }}
            color={value.color}
          >
            {value.label}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];

  const saveAuditLogFilters = () => {
    let dataObj = {
      dateFilter: {
        start_date: dayjs().subtract(1, "month"),
        end_date: dayjs().add(1, "month"),
      },
      filters: {
        asset: assetDetails?._id,
        production_line: undefined,
      },
      pageNumber: 1,
    };
    setItem("applied_filters", dataObj, true);
  };

  return (
    <Card
      title="Audit Logs"
      extra={
        auditLogData?.totalLogs > showCount ? (
          <Typography
            style={{
              color: "#1677ff",
              cursor: "pointer",
              fontSize: 15,
              textDecoration: "underline",
            }}
            onClick={() => {
              navigate(`/audit-logs`);
              saveAuditLogFilters();
            }}
          >
            View all
          </Typography>
        ) : null
      }
      styles={{
        header: { backgroundColor: "#fff" },
        body: { padding: "0px" },
      }}
    >
      {/* <AuditLogs child={true} /> */}
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: 500, y: 200 }}
            columns={tableColumns}
            dataSource={auditLogData?.logs?.slice(0, showCount) || []}
            pagination={false}
            components={tableComponents}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default AuditLog;
