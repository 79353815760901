import { Card, Flex, Input, Form, DatePicker } from "antd";
import React, { useContext } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

import { deleteDocs } from "../../../helpers/utility";
import CommonUpload from "../../Blocks/CommonUpload";
import { UserContext } from "../../../context/UserProvider";
import { handleDynamicValidations } from "./helper";

const WarrantyDetails = ({ form, documents, setDocuments, edit }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const onUpload = async (resp) => {
    if (!resp) return;

    if (resp?.path) {
      const updatedWarrantyFiles = [...(documents?.warranty || []), resp?.path];
      await form.setFieldValue(["warranty", "files"], updatedWarrantyFiles);
      await handleDynamicValidations(
        { warranty: { files: updatedWarrantyFiles } },
        form.getFieldsValue(),
        form
      );

      setDocuments({
        ...documents,
        warranty: [...(documents?.warranty || []), resp?.path],
      });
    }
  };

  const handleDisabledEndDate = (current) => {
    const startDate = form.getFieldValue()?.warranty?.start_date;
    return current && current.isBefore(startDate, "day");
  };

  return (
    <Card
      title="Warranty Details"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Flex gap={24}>
        <Form.Item
          label="Provider company"
          name={["warranty", "provider_company"]}
          style={{ width: "50%" }}
        >
          <Input
            placeholder="Company"
            style={{ width: "100%" }}
            className={inputStyles.smInput}
          />
        </Form.Item>
        <Form.Item
          label="Warranty type"
          name={["warranty", "type"]}
          style={{ width: "50%" }}
        >
          <Input
            placeholder="Manufacturer’s warranty/ extended warranty"
            style={{ width: "100%" }}
            className={inputStyles.smInput}
          />
        </Form.Item>
      </Flex>
      <Flex gap={24}>
        <Form.Item
          label="Start date"
          name={["warranty", "start_date"]}
          style={{ width: "50%" }}
        >
          <DatePicker
            format="YYYY-MM-DD"
            onChange={() => {
              form.setFieldsValue({ warranty: { end_date: null } });
              handleDynamicValidations(
                { warranty: { end_date: null } },
                form.getFieldsValue(),
                form
              );
            }}
            placeholder="Select start date"
            style={{ width: "100%" }}
            size="medium"
          />
        </Form.Item>
        <Form.Item
          label="End date"
          format="YYYY-MM-DD"
          style={{ width: "50%" }}
          name={["warranty", "end_date"]}
        >
          <DatePicker
            disabledDate={handleDisabledEndDate}
            size="medium"
            placeholder="Select end date"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Flex>
      <Flex gap={24}>
        <Form.Item
          label="Provider contact name"
          name={["warranty", "contact_person"]}
          style={{ width: "50%" }}
        >
          <Input
            style={{ width: "100%" }}
            placeholder="Provider contact name"
            className={inputStyles.smInput}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Please enter a valid 10-digit phone number",
            },
          ]}
          name={["warranty", "contact_number"]}
          label="Provider contact number"
          style={{ width: "50%" }}
        >
          <Input
            style={{ width: "100%" }}
            placeholder="Provider contact number"
            className={inputStyles.smInput}
          />
        </Form.Item>
      </Flex>
      <Form.Item
        label="Additional notes"
        name={["warranty", "notes"]}
        rules={[
          {
            max: 500,
            message: "Length cannot exceed 500 characters",
          },
        ]}
      >
        <Input.TextArea
          style={{ width: "100%" }}
          rows={3}
          placeholder="Terms & Conditions"
          className={inputStyles.smInput}
        />
      </Form.Item>
      <Form.Item
        name={["warranty", "files"]}
        style={{ margin: "0px", height: "0px" }}
      >
        <div></div>
      </Form.Item>
      <Form.Item>
        <CommonUpload
          onDelete={async (e) => {
            deleteDocs(false, e?.name, storageType);
            const updatedWarrantyFiles = documents?.warranty?.filter(
              (id) => id != e?.name
            );
            await form.setFieldValue(
              ["warranty", "files"],
              updatedWarrantyFiles
            );
            handleDynamicValidations(
              { warranty: { files: updatedWarrantyFiles } },
              form.getFieldsValue(),
              form
            );
            setDocuments({
              ...documents,
              warranty: updatedWarrantyFiles,
            });
          }}
          disabled={!edit}
          onUpload={onUpload}
          dragger={false}
          documents={documents?.warranty}
        />
      </Form.Item>
    </Card>
  );
};

export default WarrantyDetails;
