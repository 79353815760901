import { Button, Col, Form, Modal, Row, Select, TreeSelect } from "antd";
import React, { useEffect, useRef, useState } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import { getTaskTypeApi } from "../../services/organisation.services";

const WoFilters = ({
  showFilterModal,
  setShowFilterModal,
  assets,
  productionLines,
  filters,
  setFilters,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();
  const [woTypes, setWoTypes] = useState([]);

  const fetchMROTypes = async () => {
      try {
        const resp = await getTaskTypeApi();
        const transformedData = resp.data.map((item) => ({
          title: item?.name,
          value: item?._id,
        }));
        setWoTypes(transformedData);
      } catch (error) {
        console.error("Error fetching MRO types:", error);
      }
    };


  const priorityOptions = [
    {
      title: "Low",
      value: "low",
    },
    {
      title: "Moderate",
      value: "moderate",
    },
    {
      title: "High",
      value: "high",
    },
  ];

  const statusOptions = [
    {
      title: "Open",
      value: "open",
    },
    {
      title: "On Hold",
      value: "on-hold",
    },
    {
      title: "In Progress",
      value: "in-progress",
    },
    {
      title: "Closed",
      value: "closed",
    },
  ];

  const verifyStatuses = [
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Not Verified",
      value: "not-verified",
    },
  ];

  useEffect(() => {
    if (showFilterModal) {
      fetchMROTypes();
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    setFilters({
      asset: values?.asset,
      production_line: values?.production_line,
      typeObj: values?.typeObj,
      priorities: values?.priorities,
      statuses: values?.statuses,
      verification: values?.verification,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset" name={"asset"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type asset name"
                  allowClear
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={assets}
                  onChange={(e) =>
                    form.setFieldValue("production_line", undefined)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Production Line" name={"production_line"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type production line name"
                  allowClear
                  showSearch={true}
                  optionFilterProp="label"
                  options={productionLines}
                  onChange={(e) => form.setFieldValue("asset", undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="WO Type" name={"typeObj"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="WO Type"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={woTypes}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Priority" name={"priorities"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Priority"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={priorityOptions}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="WO Status" name={"statuses"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="WO Status"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={statusOptions}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="WO Verification" name={"verification"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="WO Verification"
                  allowClear
                  options={verifyStatuses}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default WoFilters;
