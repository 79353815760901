import React, { useContext, useRef, useState } from "react";
import { Col, Row, Typography, Button, Image, message } from "antd";
import Webcam from "react-webcam";
import { UserContext } from "../../context/UserProvider";

import { deleteDocs, uploadFunc } from "../../helpers/utility";

const { Title, Text } = Typography;

const FaceLogin = ({
  setImageUrl,
  imageUrl,
  setShowUpload,
  form,
  existUrl,
}) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const webcamRef = useRef(null);
  const [webcamError, setWebcamError] = useState(false);

  const capture = async () => {
    // if(imageUrl && imageUrl !== existUrl){
    //   deleteDocs(true, imageUrl, "cloud");
    // }
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      try {
        const blob = await fetch(imageSrc).then((res) => res.blob());
        const file = new File([blob], "captured-face.jpg", {
          type: "image/jpeg",
        });
        console.log("file", file);
        const uid = `face-upload-${new Date().getTime()}-${userDetails?._id}`;
        file.uid = uid;
        const uploadInfo = { file };
        const resp = await uploadFunc(
          true,
          uploadInfo,
          "cloud",
          undefined,
          true
        );
        if (resp) {
          if (
            (!existUrl && imageUrl) ||
            (imageUrl && existUrl && imageUrl !== existUrl)
          ) {
            await deleteDocs(true, imageUrl, "cloud");
          }
          setImageUrl(resp?.path);
          setShowUpload(false);
        }
      } catch (error) {
        message.error("error in uploading image");
        console.error("Error handling submit:", error);
      }
    }
  };

  const handleWebcamError = (err) => {
    console.error("Webcam error: ", err);
    setWebcamError(true);
  };

  return (
    <>
      <Row align="middle" justify="center" style={{ marginBottom: "20px" }}>
        <Col>
          <Text type="secondary">
            Please take your picture to enable face login.
          </Text>
        </Col>
      </Row>

      <Row
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {webcamError ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Text type="danger">
              Sorry, we couldn't access your webcam. Please try again.
            </Text>
            <Button
              onClick={() => setWebcamError(false)}
              type="primary"
              style={{ marginTop: "15px" }}
            >
              Try Again
            </Button>
          </div>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: "user" }}
              onUserMediaError={handleWebcamError}
              style={{
                borderRadius: "50%",
                width: "250px",
                height: "300px",
                objectFit: "cover",
                marginBottom: "15px",
              }}
            />
            <Button
              type="primary"
              onClick={capture}
              className="capture-btn"
              style={{ marginTop: "15px" }}
            >
              Capture Face
            </Button>
          </>
        )}
      </Row>
    </>
  );
};

export default FaceLogin;
