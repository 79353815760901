import {
  Card,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Row,
  Select,
  Tag,
  Typography,
  TreeSelect,
  Breadcrumb,
  ConfigProvider,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  getAllAssetsApi,
  getAssetLocationByIDApi,
  getProductionLineLocationByIDApi,
} from "../../../services/assets.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import {
  convertUTCToLocalFormat,
  getAssetsPath,
  priorityOptions,
} from "../../../helpers/utility";
import { getUsersandGroupsApi } from "../../../services/users.services";
import WODocuments from "./WODocumnts";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE } from "../../../constants/defaultKeys";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  getTaskTypeApi,
  getTaskTypeByIdApi,
} from "../../../services/organisation.services";

const GeneralDetails = ({
  form,
  workOrderDetails,
  edit,
  woDocuments,
  setWoDocuments,
  setSopData,
  productionLines,
  setPlId,
  disableType,
  mro_id,
  scheduled_start_date,
}) => {
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });
  const [supervisors, setSuperVisors] = useState([]);
  const [technicians, setTechinicans] = useState([]);
  const [location, setLocation] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [workTypeOptions, setWorkTypeOptions] = useState([]);
  const [issueCodeMapping, setIssueCodeMapping] = useState({});
  const [filteredIssueCodes, setFilteredIssueCodes] = useState([]);

  const fetchWOTypes = async () => {
    try {
      const resp = await getTaskTypeApi();
      let issueCodeMap = {};
      const transformedData = resp.data.map((item) => {
        issueCodeMap = {
          ...issueCodeMap,
          [item?._id]:
            item?.issueCodes?.length > 0
              ? item?.issueCodes?.map((i) => ({
                  label: (
                    <div
                      style={{
                        gap: 8,
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tag className={tagStyles.smTag} color="blue">
                        {i?.code}
                      </Tag>
                      {i?.description && (
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {i?.description}
                        </span>
                      )}
                    </div>
                  ),
                  // label: i?.code,
                  value: i?._id,
                }))
              : [],
        };
        return {
          label: item.name,
          value: item._id,
        };
      });

      setIssueCodeMapping(issueCodeMap);
      setWorkTypeOptions(transformedData);
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  const handleWorkOrderTypeChange = (value) => {
    form.setFieldsValue({ issueCode: undefined });
    setFilteredIssueCodes(issueCodeMapping[value] || []);
  };
  useEffect(() => {
    const selectedTypeObj = form.getFieldValue("typeObj");
    if (selectedTypeObj) {
      setFilteredIssueCodes(issueCodeMapping[selectedTypeObj] || []);
    } else {
      setFilteredIssueCodes([]);
    }
  }, [form.getFieldValue("typeObj"), issueCodeMapping]);
  const handleAssetChange = async (value, e) => {
    getLocation(value, "asset");
    if (e?.image) {
      setImage({
        name: e?.image,
        status: e?.status,
      });
    } else {
      setImage({ name: "", status: "" });
    }
    setPlId(null);
    form.setFieldValue("production_line", undefined);
  };

  const handleProductionLineChange = (value) => {
    getLocation(value, "production-line");
    // let plObject = productionLines?.filter((i) => i?.value === value)?.[0];
    // setLocation([{ title: plObject?.location?.name }]);
    setImage({ name: "", status: "" });
    setPlId(value);
    form.setFieldValue("asset", undefined);
  };

  const getLocation = async (id, type = "asset") => {
    try {
      const resp =
        type === "production-line"
          ? await getProductionLineLocationByIDApi(id)
          : await getAssetLocationByIDApi(id);
      if (resp?.data) {
        setLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
    } catch (error) {}
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          code: asset?.code,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {}
  };

  const getTecniciansandGroups = async (location_id) => {
    try {
      const params = {};
      params.role = "technician";

      const resp = await getUsersandGroupsApi(params);
      let assignedUsers = [],
        assignedGroups = [];

      const users = resp?.data?.users?.map((user) => {
        workOrderDetails?.assigned_users?.includes(user?._id) &&
          assignedUsers.push(user?._id);
        return {
          value: user._id,
          title: `${user?.first_name} ${user?.last_name || ""}`,
          group: false,
          id: user?._id,
        };
      });

      const groups = resp?.data?.groups?.map((group) => {
        workOrderDetails?.assigned_groups?.includes(group?._id) &&
          assignedGroups.push(group?._id);
        return {
          value: group._id,
          title: group.name,
          group: true,
          children: group.members.map((member) => ({
            value: `${member._id}-${group?._id}`,
            title: `${member?.first_name} ${member?.last_name || ""}`,
            group: false,
            id: member?._id,
          })),
        };
      });

      setTechinicans([...users, ...groups]);
      form.setFieldsValue({
        assigned_users: assignedUsers,
        assigned_groups: assignedGroups,
        assigned_list: [...assignedGroups, ...assignedUsers],
      });
    } catch (error) {}
  };

  const getUserByRole = async (role, searchValue) => {
    try {
      const params = {};
      if (searchValue) params.name = searchValue;
      if (role || role?.length) params.role = role;

      const resp = await getUsersandGroupsApi(params);

      let assignedSupervisor = [];
      const users = resp?.data?.users?.map(({ first_name, last_name, _id }) => {
        if (workOrderDetails?.verifiers?.includes(_id)) {
          assignedSupervisor.push(_id);
        }
        return {
          value: _id,
          label: `${first_name} ${last_name || ""}`,
          group: false,
        };
      });

      setSuperVisors(users);
      form.setFieldValue("verifiers", assignedSupervisor);
    } catch (error) {}
  };

  useEffect(() => {
    if (workOrderDetails?.asset) {
      getLocation(workOrderDetails?.asset, "asset");
    } else if (workOrderDetails?.production_line) {
      getLocation(workOrderDetails?.production_line, "production-line");
    }
    // const id = workOrderDetails?.asset;
    // id && getLocation(id);

    fetchWOTypes();
    setImage({
      name:
        form.getFieldValue("asset_image") ||
        (storageType == ONPREMISE
          ? workOrderDetails?.asset_image || ""
          : workOrderDetails?.asset_image),
      status:
        form.getFieldValue("asset_status") || workOrderDetails?.asset?.status,
    });
  }, [form, workOrderDetails]);

  useEffect(() => {
    if (
      !params?.id ||
      (params?.id && workOrderDetails && Object.keys(workOrderDetails).length)
    ) {
      getAssets();
      getTecniciansandGroups();
      getUserByRole(["supervisor", "manager"]);
    }
  }, [workOrderDetails]);

  const handleDisabledStartDate = (current) => {
    if (scheduled_start_date) {
      const scheduledDate = dayjs(
        convertUTCToLocalFormat(scheduled_start_date)
      );
      return current && current.isBefore(scheduledDate, "day");
    } else {
      return false;
    }
  };

  const handleDisabledStartTime = (current) => {
    if (scheduled_start_date) {
      const scheduledDate = dayjs(
        convertUTCToLocalFormat(scheduled_start_date)
      );

      const startHours = scheduledDate.hour();
      const startMinutes = scheduledDate.minute();

      if (current && current.isSame(scheduledDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(0, startHours),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                0,
                startMinutes
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  const handleDisabledEndDate = (current) => {
    const startDate = form.getFieldValue("start_date");
    return current && current.isBefore(startDate, "day");
  };

  const handleDisabledEndTime = (current) => {
    const startDate = form.getFieldValue("start_date");
    if (!startDate) {
      return {};
    }
    const startHours = startDate.hour();
    const startMinutes = startDate.minute();

    if (current && current.isSame(startDate, "day")) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => i).splice(0, startHours),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHours) {
            return Array.from({ length: 60 }, (_, i) => i).splice(
              0,
              startMinutes
            );
          }
          return [];
        },
      };
    }
    return {};
  };

  return (
    <Card
      title="Work Order Details"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            name={"asset_status"}
            style={{ height: "0px", margin: "0px" }}
          ></Form.Item>

          <Form.Item
            style={{ height: "0px", margin: "0px" }}
            name={"asset_image"}
          ></Form.Item>
          <Form.Item
            label="Title"
            name={"name"}
            rules={[
              {
                whitespace: true,
                message:
                  "Workorder title cannot be empty or contain only spaces",
              },
              { required: true, message: "Please enter workorder title" },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              placeholder="Enter title here"
              disabled={!edit}
              className={inputStyles.smInput}
            />
          </Form.Item>
          <Flex
            style={{
              width: "100%",
              flexDirection: "column",
              marginBottom: "12px",
            }}
          >
            <Form.Item
              style={{
                width: "100%",
                marginBlock: "0px 6px",
              }}
              label={"Asset"}
              name={"asset"}
            >
              <Select
                disabled={params?.id || mro_id ? true : false}
                placeholder="Select asset"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.name
                    ?.toUpperCase()
                    .includes(inputValue.toUpperCase()) ||
                  option.code?.toString().includes(inputValue)
                }
                onChange={handleAssetChange}
                options={assets}
              />
            </Form.Item>
          </Flex>
          <Typography style={{ textAlign: "center" }}>or</Typography>

          <Form.Item
            style={{
              width: "100%",
              marginBlock: "0px 6px",
            }}
            label={"Production line"}
            name={"production_line"}
          >
            <Select
              disabled={params?.id || mro_id ? true : false}
              placeholder="Select production line"
              style={{
                width: "100%",
              }}
              className={inputStyles.smSelect}
              onChange={handleProductionLineChange}
              options={productionLines}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.name.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
          {location?.length > 0 && (
            <Flex>
              <Form.Item label="Location">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "0px",
                    padding: "4px 12px",
                  }}
                >
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: "#2f3542",
                          lastItemColor: "#2f3542",
                        },
                      },
                    }}
                  >
                    <Breadcrumb separator=">" items={location} />
                  </ConfigProvider>
                </div>
              </Form.Item>
            </Flex>
          )}
          <Flex style={{ width: "100%" }} gap={12}>
            <Form.Item
              style={{
                width: "50%",
              }}
              label={"Work Order Type"}
              name={"typeObj"}
            >
              <Select
                disabled={!edit || disableType}
                placeholder="Select work order type"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                onChange={handleWorkOrderTypeChange}
                options={workTypeOptions}
                allowClear
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "50%",
              }}
              label={"Priority"}
              name={"priority"}
            >
              <Select
                disabled={!edit}
                placeholder="Select priority"
                defaultValue={"moderate"}
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                onChange={() => {}}
                options={priorityOptions}
              />
            </Form.Item>
          </Flex>
          {(form.getFieldValue("issueCode") ||
            (edit && filteredIssueCodes.length > 0)) && (
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Issue Code"}
              name={"issueCode"}
            >
              <Select
                disabled={!edit || disableType}
                placeholder="Select issue code"
                style={{
                  width: "100%",
                }}
                className={inputStyles.smSelect}
                options={filteredIssueCodes}
                allowClear
              />
            </Form.Item>
          )}
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_users"}
          ></Form.Item>
          <Form.Item
            style={{
              height: "0px",
              margin: "0px",
            }}
            name={"assigned_groups"}
          ></Form.Item>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label={"Assign technician"}
              name={"assigned_list"}
            >
              <TreeSelect
                allowClear
                multiple={true}
                treeCheckable={true}
                className={inputStyles.smSelect}
                showSearch
                placeholder={"Assign technician"}
                treeData={technicians}
                style={{ width: "100%" }}
                showCheckedStrategy="SHOW_PARENT"
                filterTreeNode={(input, treeNode) => {
                  const inputValue = input.toUpperCase();
                  const title = treeNode.title.toUpperCase();
                  const match = title.indexOf(inputValue) !== -1;

                  return match;
                }}
                onSelect={(e, values) => {
                  const id = values.value.split("-")[1];
                  if (values?.group) {
                    if (values?.children?.length === 0) {
                      message.info(
                        `There are no technicians present in "${values?.title}" group.`
                      );
                    }
                    form.setFieldsValue({
                      assigned_groups: [
                        ...(form.getFieldValue("assigned_groups") || []),
                        e,
                      ],
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: [
                        ...(form.getFieldValue("assigned_users") || []),
                        id || e,
                      ],
                    });
                  }
                }}
                disabled={!edit}
                onDeselect={(e, values) => {
                  const id = values.value.split("-")[1];
                  const updatedAssignedGroups = form
                    .getFieldValue("assigned_groups")
                    ?.filter((val) => val !== e);
                  const updatedAssignedUsers = form
                    .getFieldValue("assigned_users")
                    ?.filter((val) => val !== (id || e));

                  if (values?.group) {
                    form.setFieldsValue({
                      assigned_groups: updatedAssignedGroups,
                    });
                  } else {
                    form.setFieldsValue({
                      assigned_users: updatedAssignedUsers,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Verifiers"
              name={"verifiers"}
            >
              <Select
                className={inputStyles.smSelect}
                allowClear
                placeholder={"Select verifiers"}
                mode="multiple"
                options={supervisors}
                style={{ width: "100%" }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                disabled={!edit}
              />
            </Form.Item>
          </Flex>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <Form.Item
              style={{
                width: "100%",
              }}
              label="Scheduled to start at"
              name={"start_date"}
              rules={[
                { required: true, message: "Please select start date & time" },
              ]}
            >
              <DatePicker
                disabled={!edit}
                format="YYYY-MM-DD HH:mm"
                onChange={() => form.setFieldsValue({ end_date: null })}
                placeholder="Select start date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "100%",
                }}
                showNow={false}
                disabledDate={handleDisabledStartDate}
                disabledTime={handleDisabledStartTime}
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "100%",
              }}
              name={"end_date"}
              label="Scheduled to close at"
            >
              <DatePicker
                disabledDate={handleDisabledEndDate}
                disabledTime={handleDisabledEndTime}
                disabled={!edit}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select end date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                showNow={false}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Flex>
          <Flex
            style={{
              width: "100%",
            }}
            gap={12}
          >
            <div style={{ width: "50%", marginTop: "16px" }}>
              <WODocuments
                form={form}
                woDocuments={woDocuments}
                setWoDocuments={setWoDocuments}
                edit={edit}
              />
            </div>
          </Flex>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
            {image?.name ? (
              <Image
                src={getAssetsPath(image.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
