import React, { useContext } from "react";
import { Button, Typography, Modal, Tag, message } from "antd";
import buttonStyles from "../../assets/styles/Custom/Button.module.css";
import textStyles from "../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

import { setCookie } from "../../helpers/localStorage";
import {
  ACTIVEDOMAIN,
  FACILITYID,
  ORGANISATION,
} from "../../constants/defaultKeys";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter, userRoles } from "../../helpers/utility";
import RandomAvatar from "../Blocks/RandomAvatar";
import CreateOrgForm from "./CreateOrgForm";
import { UserContext } from "../../context/UserProvider";
import { redirectToDashboard } from "../../helpers/domainUtility";

const ChooseOrgModal = ({ openModal, closable, onCancel, loginType = "" }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const navigate = useNavigate();
  console.log("login type : ", loginType);
  return (
    <Modal
      open={openModal}
      centered
      onCancel={onCancel}
      footer={null}
      closable={closable ? closable : false}
      style={{ textAlign: "center", padding: "0px", position: "relative" }}
      className={"orgModal"}
    >
      <div
        style={{
          padding: "24px",
          backgroundPosition: "top center",
          backgroundSize: "auto 170px",
        }}
      >
        {userDetails?.organizations?.length == 0 ? (
          <>
            <Typography
              className={`text-center ${textStyles.body3}`}
              style={{ marginBlock: "12px" }}
            >
              Create Organisation
            </Typography>
            <CreateOrgForm current={2} changeDomain={true} />
          </>
        ) : (
          <>
            <Typography
              className={`text-center ${textStyles.body3}`}
              style={{ marginBlock: "12px" }}
            >
              Choose Organisation
            </Typography>

            <div
              className={"scrollDiv"}
              style={{
                maxHeight: "50vh",
                overflowY: "auto",
              }}
            >
              {userDetails?.organizations?.map((org) => {
                const orgRole = userRoles?.filter(
                  (eachRole) => eachRole?.value == org?.role
                )?.[0];

                return (
                  <Button
                    key={org?.organization?._id}
                    className={`orgButton ${buttonStyles.mdBtn}`}
                    style={{
                      width: "100%",
                      marginTop: "12px",
                      justifyContent: "start",
                      paddingBlock: "8px 16px",
                    }}
                    onClick={() => {
                      if (
                        !loginType ||
                        (loginType === "face" &&
                          org?.organization?.settings?.face_login)
                      ) {
                        setUserDetails((prev) => ({
                          ...prev,
                          activeOrg: org,
                        }));
                        setCookie(
                          ORGANISATION,
                          org?.organization?._id,
                          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                          "/"
                        );
                        setCookie(
                          ACTIVEDOMAIN,
                          org?.organization?.domain,
                          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                          "/"
                        );
                        setCookie(
                          FACILITYID,
                          "",
                          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                          "/"
                        );
                        redirectToDashboard(
                          org?.organization?.domain,
                          org?.organization?.settings?.storage,
                          navigate
                        );
                      } else if (
                        loginType === "face" &&
                        !org?.organization?.settings?.face_login
                      ) {
                        message.error(
                          "Face login is not enabled for this organisation"
                        );
                      }
                    }}
                  >
                    <div className="flex-between" style={{ width: "100%" }}>
                      <div className="flex-start">
                        <RandomAvatar
                          size={30}
                          name={capitalizeFirstLetter(org?.organization?.name)}
                          url={org?.organization?.profile_pic}
                          displayName={true}
                          indexId={org?.user_org_id}
                        />

                        <span style={{ marginLeft: "12px" }}>
                          <Tag
                            color={orgRole?.color}
                            className={tagStyles.smRndTag}
                          >
                            {orgRole?.label}
                          </Tag>
                        </span>
                      </div>
                    </div>
                  </Button>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ChooseOrgModal;
