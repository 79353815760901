import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import { ConfigProvider, Tabs, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendarView from "./CalenderView";
import AssetView from "./AssetView";
import ProdLineView from "./ProdLineView";

const Scheduler = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [viewType, setViewType] = useState("date");

  useEffect(() => {
    if (searchParams.get("viewType") == "asset") {
      setViewType("asset");
    } else if (searchParams.get("viewType") == "production-line") {
      setViewType("production-line");
    } else {
      setViewType("date");
    }
  }, [searchParams]);

  const tabItems = [
    { key: "date", label: "Date wise view" },
    { key: "asset", label: "Asset wise view" },
    { key: "production-line", label: "Production-Line wise view" },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            Schedule
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={viewType}
                onChange={(e) => navigate(`/scheduler?viewType=${e}`)}
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      {viewType == "asset" ? (
        <AssetView />
      ) : viewType == "production-line" ? (
        <ProdLineView />
      ) : (
        <CalendarView />
      )}
    </CustomLayout>
  );
};

export default Scheduler;
