import {
  Button,
  Col,
  Row,
  Table,
  Tag,
  Typography,
  DatePicker,
  message,
  Pagination,
  Skeleton,
  Result,
  Empty,
  Space,
  Image,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  getAllAssetsApi,
  getAssetAuditLogsApi,
  getProductionLines,
} from "../../../services/assets.services";
import { useNavigate } from "react-router-dom";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  displayFilterTag,
  scrollToTopTable,
  tableComponents,
  workOrderstatusList,
} from "../../../helpers/utility";
import dayjs from "dayjs";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import CustomLayout from "../../Layout";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { PAGESIZE } from "../../../constants/defaultKeys";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import filterActiveIcon from "../../../assets/images/filterActiveIcon.svg";
import AuditLogFilters from "./AuditLogFilters";
import { getItem, removeItem, setItem } from "../../../helpers/localStorage";

const { RangePicker } = DatePicker;

const AuditLogsTable = ({ child = false, setAssetDetails }) => {
  const tableContainerRef = useRef(null);
  const navigate = useNavigate();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(2, "month"),
    end_date: dayjs(),
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  const getAuditLogs = async (data, clearLocStorage, download = false) => {
    let query = {};
    if (download) {
      query = {
        ...query,
        download: true,
      };
    } else {
      query = { ...query, page: data?.pageNumber };
    }
    query = {
      ...query,
      start_date: data?.dateFilter.start_date
        ? convertToUTCFormat(data?.dateFilter.start_date.startOf("day"))
        : undefined,
      end_date: data?.dateFilter.end_date
        ? convertToUTCFormat(data?.dateFilter.end_date.endOf("day"))
        : undefined,
    };
    data?.filters?.asset && (query.asset = data?.filters?.asset);
    data?.filters?.production_line &&
      (query.production_line = data?.filters?.production_line);

    download ? setDownloadLoading(true) : setLoading(true);
    try {
      const response = await getAssetAuditLogsApi(query, download);
      if (download) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "audit_logs.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        setLogs(response?.data);
      }
    } catch (error) {
      console.error("Error fetching audit logs:", error);
      if (download) {
        message.error("Error downloading audit logs");
      }
    } finally {
      download ? setDownloadLoading(false) : setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  useEffect(() => {
    const fetchData = async (data, status = false) => {
      await getAuditLogs(data, status);
      scrollToTopTable(tableContainerRef);
    };

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      fetchData(
        {
          ...locStorageData,
          dateFilter: {
            start_date: locStorageData?.dateFilter?.start_date
              ? dayjs(locStorageData?.dateFilter?.start_date)
              : null,
            end_date: locStorageData?.dateFilter?.end_date
              ? dayjs(locStorageData?.dateFilter?.end_date)
              : null,
          },
        },
        true
      );
    } else {
      fetchData({
        dateFilter: dateFilter,
        filters: filters,
        pageNumber: pageNumber,
      });
    }
    // fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [dateFilter, filters]);

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setDateFilter({
        start_date: locStorageData?.dateFilter?.start_date
          ? dayjs(locStorageData?.dateFilter?.start_date)
          : null,
        end_date: locStorageData?.dateFilter?.end_date
          ? dayjs(locStorageData?.dateFilter?.end_date)
          : null,
      });
      setFilters({
        asset: locStorageData?.filters?.asset || undefined,
        production_line: locStorageData?.filters?.production_line || undefined,
      });
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  const saveAuditLogFilters = () => {
    let dataObj = {
      dateFilter: dateFilter,
      filters: filters,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const columns = [
    {
      title: "Asset code",
      dataIndex: "code",
      key: "code",
      width: 150,
      render: (_, record) => record?.asset?.code || "-",
    },

    {
      title: "Asset/ Production line",
      dataIndex: "asset",
      key: "asset",
      width: 300,
      render: (_, record) =>
        record?.asset?.name || record?.production_line?.name || "-",
    },
    {
      title: "Location",
      // title: <Typography className="tableHeader">Task name</Typography>,
      dataIndex: "location",
      key: "location",
      width: 300,
      render: (text, record) => {
        return (
          <span>
            {record?.asset?.location?.name ||
              record?.production_line?.location?.name ||
              "-"}
          </span>
        );
      },
      // display: child ? false : true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("DD MMM YYYY")
          : "-";
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: 150,
      render: (val, record) => {
        let currDate =
          record?.workorder?.start_date || record?.occurrence?.occurrence_date;
        return currDate
          ? dayjs(convertUTCToLocalFormat(currDate)).format("hh:mm A")
          : "-";
      },
    },
    {
      title: "Task code",
      dataIndex: "workorder",
      key: "workorder",
      width: 150,
      render: (_, record) => {
        let taskId = record?.workorder?._id || record?.occurrence?._id || "";
        let taskCode =
          record?.workorder?.code || record?.occurrence?.code || "";
        let taskType = taskCode?.includes("WO")
          ? "work-orders"
          : taskCode?.includes("INS")
          ? "inspections"
          : "maintenances";
        if (taskId) {
          return (
            <Typography
              style={{ color: "#1677ff", cursor: "pointer" }}
              onClick={() => {
                navigate(
                  `/${taskType}/${taskId}${
                    taskType === "work-orders" ? "" : "/job"
                  }`,
                  { state: { source: "/audit-logs" } }
                );
                saveAuditLogFilters();
              }}
            >
              {taskCode}
            </Typography>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Task name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text, record) => {
        const workorderName = record?.workorder?.name;
        const occurrenceName = record?.occurrence?.maintenance?.name;
        return <span>{workorderName || occurrenceName || "-"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 160,
      render: (text, record) => {
        const status = record?.workorder?.status || record?.occurrence?.status;
        const value = workOrderstatusList.find(
          (statusItem) => statusItem.value === status
        );
        return value ? (
          <Tag
            className={tagStyles.smTag}
            style={{ fontWeight: "600", minWidth: "80px", textAlign: "left" }}
            color={value.color}
          >
            {value.label}
          </Tag>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Audit Logs</Typography>}
    >
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <Space
            style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
          >
            <Button
              style={{ color: "#6941C6" }}
              className={btnStyles.smBtn}
              type="default"
              icon={
                <Image
                  src={filterActiveIcon}
                  width={18}
                  height={18}
                  alt="filter"
                  preview={false}
                />
              }
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
          </Space>
          {showFilterModal && (
            <AuditLogFilters
              showFilterModal={showFilterModal}
              setShowFilterModal={setShowFilterModal}
              assets={assets}
              productionLines={productionLines}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Col>
        <Col offset={4} span={10} style={{ textAlign: "right" }}>
          <Space style={{ gap: 12 }}>
            <Button
              onClick={() =>
                getAuditLogs(
                  {
                    dateFilter: dateFilter,
                    filters: filters,
                    pageNumber: pageNumber,
                  },
                  false,
                  true
                )
              }
              type="primary"
              loading={downloadLoading}
            >
              Download Report
            </Button>
            <RangePicker
              format="DD-MMM-YYYY"
              allowClear={false}
              value={[dateFilter.start_date, dateFilter.end_date]}
              onChange={(dates, dateString) =>
                setDateFilter({
                  start_date: dates?.[0],
                  end_date: dates?.[1],
                })
              }
            />
          </Space>
        </Col>
        {(filters?.asset || filters?.production_line) && (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 6,
              flexWrap: "wrap",
            }}
          >
            {filters?.asset && displayFilterTag("Asset", "purple")}
            {filters?.production_line &&
              displayFilterTag("Production Line", "purple")}
            <Typography
              style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
              onClick={() =>
                setFilters({
                  asset: undefined,
                  production_line: undefined,
                })
              }
            >
              Clear All
            </Typography>
          </Col>
        )}
        <Col span={24}>
          <Table
            ref={tableContainerRef}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            bordered
            columns={columns}
            dataSource={logs?.logs}
            pagination={false}
            scroll={{
              x: 1200,
              y: "60vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No logs found</Typography>}
                />
              ),
            }}
            components={tableComponents}
          />
        </Col>
        {logs?.totalLogs > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={logs?.totalLogs}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default AuditLogsTable;
