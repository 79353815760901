import { Col, ConfigProvider, Flex, Image, Menu, Row } from "antd";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../assets/images/cmms-logos/CMMSLogo.svg";
import bolt from "../../assets/images/cmms-logos/bolt.png";

import dashboardInactive from "../../assets/images/sidemenuIcons/dashboardInactive.svg";
import dashboardActive from "../../assets/images/sidemenuIcons/dashboardActive.svg";
import pmActive from "../../assets/images/sidemenuIcons/pmActive.svg";
import pmInactive from "../../assets/images/sidemenuIcons/pmInactive.svg";

import assetsActive from "../../assets/images/sidemenuIcons/assetActive.svg";
import assetsInactive from "../../assets/images/sidemenuIcons/assetInactive.svg";
import locationsActive from "../../assets/images/sidemenuIcons/locationsActive.svg";
import locationsInactive from "../../assets/images/sidemenuIcons/locationsInactive.svg";
import mroActive from "../../assets/images/sidemenuIcons/mroActive.svg";
import mroInactive from "../../assets/images/sidemenuIcons/mroInactive.svg";
import inventoryActive from "../../assets/images/sidemenuIcons/inventoryActive.svg";
import inventoryInactive from "../../assets/images/sidemenuIcons/inventoryInactive.svg";
import auditLogsActive from "../../assets/images/sidemenuIcons/auditLogsActive.svg";
import auditLogsInactive from "../../assets/images/sidemenuIcons/auditLogsInactive.svg";
import knowledgeHubActive from "../../assets/images/sidemenuIcons/KnowledgeHub.svg";
import "./Layout.css";
import { UserContext } from "../../context/UserProvider";
import RandomAvatar from "../Blocks/RandomAvatar";
import { capitalizeFirstLetter } from "../../helpers/utility";
import {
  ArrowRightOutlined,
  SettingOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import ChooseOrgModal from "../Organisations/ChooseOrgModal";
import { getItem, removeItem } from "../../helpers/localStorage";

const SideMenu = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [openKeys, setOpenKeys] = useState([
    "knowledge-hub",
    "maintenances",
    "asset-registry",
  ]);

  const finalLogo =
    userDetails?.activeOrg?.organization?.profile_pic &&
    userDetails.activeOrg.organization.profile_pic.trim() !== ""
      ? userDetails.activeOrg.organization.profile_pic
      : logo;

  const handleOnOpen = () => {
    setOpenModal(true);
  };

  function getMenuItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  function getImage(path, activeimage, inactiveImage) {
    return (
      <Image
        preview={false}
        src={
          path === location.pathname.split("/")[1] ? activeimage : inactiveImage
        }
        id={path}
        alt={path}
        style={{
          height: collapsed ? "22px" : "18px",
          width: "auto",
          verticalAlign: "text-bottom",
          marginRight: "18px",
        }}
      />
    );
  }
  const items = [
    getMenuItem(
      "Dashboard",
      "dashboard",
      getImage("dashboard", dashboardActive, dashboardInactive)
    ),
    getMenuItem("MROs", "mro", getImage("mro", mroActive, mroInactive)),

    getMenuItem(
      "Maintenance",
      "maintenances",
      getImage("maintenances", pmActive, pmInactive),
      [
        getMenuItem("Preventive", "maintenances"),
        getMenuItem("Inspections", "inspections"),
        getMenuItem("Work Orders", "work-orders"),
        getMenuItem("Schedule", "scheduler"),
      ]
    ),

    getMenuItem(
      "Knowledge Hub",
      "knowledge-hub",
      getImage("scheduler", knowledgeHubActive, knowledgeHubActive),
      [
        getMenuItem("Procedures", "procedures"),
        getMenuItem("Trainings", "trainings"),
      ]
    ),

    ...(userDetails?.activeOrg?.organization?.settings?.inventory_module
      ? [
          getMenuItem(
            "Inventory",
            "inventory",
            getImage("inventory", inventoryActive, inventoryInactive)
          ),
        ]
      : []),
    getMenuItem(
      "Audit Logs",
      "audit-logs",
      getImage("audit-logs", auditLogsActive, auditLogsInactive)
    ),
    getMenuItem(
      "Asset Registry",
      "asset-registry",
      getImage("assets", assetsActive, assetsInactive),
      [
        getMenuItem("Assets", "assets"),
        getMenuItem("Production Lines", "production-lines"),
        getMenuItem("Asset Categories", "asset-categories"),
      ]
    ),
    getMenuItem(
      "Locations",
      "locations",
      getImage("locations", locationsActive, locationsInactive)
    ),
    getMenuItem(
      "Organization",
      "org",
      <SettingOutlined
        style={{
          color:
            location.pathname.split("/")[1] === "org" ? "#7369F4" : "#667085",
          fontSize: collapsed ? 20 : 18,
          marginRight: 7,
        }}
      />
    ),
  ];

  return (
    <Row style={{ height: "100%" }} align={"top"} gutter={[0, 24]}>
      <ChooseOrgModal
        openModal={openModal}
        onOpenChange={handleOnOpen}
        closable={true}
        onCancel={() => setOpenModal(false)}
      />
      <Col span={24}>
        <ConfigProvider
          theme={{
            theme: {
              fontFamily: "Montserrat",
            },
            components: {
              Menu: {
                itemBg: "#2f3542",
                itemColor: "#2f3542",
                itemSelectedColor: "#7369F4",
                itemSelectedBg: "#f0f1f6",
                itemHoverBg: "#fff",
                itemHoverColor: "#2f3542",
              },
            },
          }}
        >
          <Flex
            align="center"
            justify={collapsed ? "center" : "space-between"}
            style={{
              marginBottom: "24px",
              minHeight: "50px",
              gap: "8px",
              paddingLeft: 12,
              paddingRight: 12,
            }}
          >
            <Image
              preview={false}
              className={
                collapsed
                  ? "sideMenuLogoWithCollapse"
                  : "sideMenuLogoWithNoCollapse"
              }
              src={collapsed ? bolt : finalLogo ? finalLogo : logo}
              alt="Bolt Logo"
              style={{ marginLeft: "20px", height: "50px", width: "auto" }}
            />
            {!collapsed ? (
              <VerticalRightOutlined
                style={{ fontSize: "20px", color: "#2f3542" }}
                onClick={() => setCollapsed((prev) => !prev)}
              />
            ) : (
              <VerticalLeftOutlined
                style={{ fontSize: "20px", color: "#2f3542" }}
                onClick={() => setCollapsed((prev) => !prev)}
              />
            )}
          </Flex>
          <Menu
            className={collapsed ? "sideMenuCollapsed" : "sideMenu"}
            style={{ border: "0px", maxHeight: "75vh", overflowY: "auto" }}
            selectedKeys={[location.pathname.split("/")[1]]}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={(keys) => setOpenKeys(keys)}
            items={items}
            onClick={(e) => {
              let locStorageData = getItem("applied_filters", true);
              if (!location?.pathname?.includes(e.key) && locStorageData) {
                removeItem("applied_filters");
              }
              if (e.key == "org") {
                navigate(`/org/users`);
              } else {
                navigate(`/${e.key}`);
              }
            }}
          />
        </ConfigProvider>
      </Col>
      <Col span={24} style={{ placeSelf: "end" }}>
        <div
          onClick={() => {
            setOpenModal(true);
          }}
          className="flex-between"
          style={{
            paddingTop: "24px",
            cursor: "pointer",
            gap: "12px",
            paddingLeft: collapsed ? 24 : 16,
            paddingRight: collapsed ? 24 : 16,
          }}
        >
          <RandomAvatar
            size={24}
            url={userDetails?.activeOrg?.organization?.profile_pic}
            name={capitalizeFirstLetter(
              userDetails?.activeOrg?.organization?.name
            )}
            displayName={!collapsed}
            fontStyle={{ fontSize: "14px", width: "80%" }}
            indexId={userDetails?.activeOrg?.user_org_id}
          />
          <ArrowRightOutlined />
        </div>
      </Col>
    </Row>
  );
};

export default SideMenu;
