import React, { useContext, useState } from "react";
import { Button, message } from "antd";
import SignatureCanvas from "react-signature-canvas";
import { uploadFunc } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

const DigitalSignatureDraw = ({
  result,
  setResult,
  form,
  setUpdating,
  setIsModalOpen,
}) => {
  const [signature, setSignature] = useState(null);
  const { userDetails, setUserDetails } = useContext(UserContext);

  const clearSignature = () => {
    signature.clear();
    setResult(null);
  };

  const saveSignature = async () => {
    if (signature.isEmpty()) {
      message.warning("Please draw a signature before saving.");
      return;
    }
    const res = signature.getTrimmedCanvas().toDataURL("image/jpg");
    try {
      const byteString = atob(res.split(",")[1]);
      const mimeString = res.split(",")[0].split(":")[1].split(";")[0];

      const byteArray = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: mimeString });

      const uid = `rc-upload-${userDetails?._id}`;
      const fileName = `${userDetails?.first_name || "signature"}.png`;

      const file = new File([blob], fileName, { type: mimeString });
      file.uid = uid;
      const uploadInfo = { file };
      const resp = await uploadFunc(true, uploadInfo);
      if (resp) {
        setResult(resp?.path);
        form.setFieldsValue({ digital_sign_img: resp?.path });
        setUpdating(true);
        setIsModalOpen(false);
        signature.clear();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "Failed to upload signature."
      );
    }
  };

  return (
    <>
      <div style={{ width: 472, height: 200, border: "1px solid #d0d5dd" }}>
        <SignatureCanvas
          ref={(ref) => {
            setSignature(ref);
          }}
          penColor="black"
          backgroundColor="rgb(255, 255, 255)"
          canvasProps={{ width: 472, height: 200, className: "sigCanvas" }}
        />
      </div>
      <div style={{ marginTop: "10px", textAlign: "end" }}>
        <Button
          onClick={clearSignature}
          color="default"
          variant="outlined"
          style={{ marginRight: "10px" }}
        >
          Clear
        </Button>

        <Button type="primary" onClick={saveSignature}>
          Save
        </Button>
      </div>
    </>
  );
};

export default DigitalSignatureDraw;
