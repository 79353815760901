import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAssetDowntimeByIdApi } from "../../../services/assets.services";
import { Card, Col, DatePicker, Flex, Row, Space, Tag, Typography } from "antd";
import { convertToUTCFormat, formatHours } from "../../../helpers/utility";
import {
  Area,
  AreaChart,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const { RangePicker } = DatePicker;

const AssetDowntimeDetails = ({ assetDetails, refresh }) => {
  const params = useParams();
  const [totalDowntime, setTotalDowntime] = useState("0 hrs 0 mins");
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "day"),
    end_date: dayjs().endOf("day"),
  });

  const getTransformedData = (dataList) => {
    let modifiedData = [],
      total = 0,
      endPresent = true,
      current = dayjs();

    dataList?.map((item) => {
      let start = item?.start_time
        ? dayjs(convertToUTCFormat(item?.start_time))
        : null;
      let end = item?.end_time
        ? dayjs(convertToUTCFormat(item?.end_time))
        : null;
      if (start && end) {
        total += end.diff(start, "hour", true);
      }
      if (start && endPresent) {
        modifiedData.push({
          time: start.format("DD-MM-YYYY HH:mm"),
          downtime: 1,
        });
      }
      if (end) {
        modifiedData.push({
          time: end.format("DD-MM-YYYY HH:mm"),
          downtime: 0,
        });
        endPresent = true;
      } else {
        endPresent = false;
      }
    });
    if (
      modifiedData?.length &&
      current > dayjs(modifiedData[modifiedData.length - 1].time)
    ) {
      modifiedData.push({
        time: current.format("DD-MM-YYYY HH:mm"),
        downtime: endPresent ? 0 : 1,
      });
    }

    let durationObj = formatHours(total);
    return {
      data: modifiedData,
      totalDowntime:
        (durationObj?.days ? `${durationObj?.days} days ` : "") +
        `${durationObj?.hours} hrs` +
        (!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""),
    };
  };

  const fetchAssetDowntimeData = async () => {
    let query = {};
    if (dateFilter?.start_date && dateFilter?.end_date) {
      query = {
        ...query,
        startTime: convertToUTCFormat(dateFilter.start_date.startOf("day")),
        endTime: convertToUTCFormat(dateFilter.end_date.endOf("day")),
      };
    }
    try {
      const resp = await getAssetDowntimeByIdApi(params?.id, query);
      if (resp?.data) {
        let transformedData = getTransformedData(resp?.data?.data);
        setData(transformedData.data);
        setTotalDowntime(transformedData.totalDowntime);
      }
    } catch (error) {
      setTotalDowntime("0 hrs 0 mins");
      setData([]);
    }
  };

  useEffect(() => {
    fetchAssetDowntimeData();
  }, [dateFilter, refresh]);

  return (
    <Card
      title={
        <Flex justify="space-between" align="center">
          <div>Asset Downtime Details</div>
          <RangePicker
            id="asset-downtime-filter"
            style={{ width: "55%" }}
            format="DD-MMM-YYYY"
            value={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(dates, dateString) => {
              setDateFilter({
                start_date: dates?.[0],
                end_date: dates?.[1],
              });
            }}
          />
        </Flex>
      }
      style={{ height: "100%" }}
    >
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Space style={{ width: "100%", gap: 16 }}>
            <Typography>Downtime</Typography>
            <Tag color="blue">{totalDowntime}</Tag>
          </Space>
        </Col>
        <Col span={24}>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart
              data={data}
              margin={{ top: 36, right: 30, left: -30, bottom: 10 }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey="time"
                //   tickFormatter={(time) => new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              />
              <YAxis domain={[0, 1]} ticks={[0, 1]} />
              <Tooltip
              //   labelFormatter={(time) => new Date(time).toLocaleString()}
              //   formatter={(value) => (value === 1 ? "Downtime" : "Uptime")}
              />
              <Area
                type="monotone"
                dataKey="downtime"
                stroke="#52c41a"
                fill="#52c41a"
                fillOpacity={0.3}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Card>
  );
};

export default AssetDowntimeDetails;
