import { Button, Col, Form, Modal, Row, TreeSelect } from "antd";
import React, { useEffect, useRef, useState } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import { getTaskTypeApi } from "../../services/organisation.services";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";

const InventoryFilters = ({
  showFilterModal,
  setShowFilterModal,
  filters,
  setFilters,
  locations,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();
  const [inventoryTypes, setInventoryTypes] = useState([]);

  const fetchInventoryTypes = async () => {
    try {
      const resp = await getTaskTypeApi();
      const transformedData = resp.data.map((item) => ({
        title: item.name,
        value: item._id,
      }));
      setInventoryTypes(transformedData);
    } catch (error) {
      console.error("Error fetching types:", error);
    }
  };

  useEffect(() => {
    if (showFilterModal) {
      fetchInventoryTypes();
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    setFilters({
      typeObj: values?.typeObj,
      location: values?.location,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Inventory Type" name={"typeObj"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Inventory Type"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={inventoryTypes}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Location" name={"location"}>
                <TreeSelect
                  showSearch={false}
                  allowClear
                  placeholder="Location"
                  className={inputStyles.smSelect}
                  treeData={Object.keys(locations)?.length > 0 && [locations]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default InventoryFilters;
