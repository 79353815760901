import axiosInstance from "../config/axios/axios.config";

export const addUserApi = (payload) => {
  return axiosInstance.post("/acls/invite", payload);
};
export const createPasswordApi = (payload) => {
  return axiosInstance.patch("/auth", payload);
};
export const loginApi = (payload) => {
  return axiosInstance.post("/auth", payload);
};


export const signUpApi = (payload) => {
  return axiosInstance.post("/auth/signup", payload);
};
export const sendOtpApi = (payload) => {
  return axiosInstance.post("/auth/mobile", payload);
};
export const reSendOtpApi = (payload) => {
  return axiosInstance.patch("/auth/mobile", payload);
};
export const validateOtpApi = (payload) => {
  return axiosInstance.post("/auth/mobile/validate", payload);
};
export const getAllUsersApi = (query) => {
  return axiosInstance.get(`/acls`, { params: query });
};
export const updateUserApi = (id, payload) => {
  return axiosInstance.put(`/acls/${id}`, payload);
};
export const updateProfileApi = (payload) => {
  return axiosInstance.put(`/acls/update-profile`, payload);
};
export const uploadFaceCloudApi = (payload) => {
  return axiosInstance.post(`/auth/upload-face-cloud`, payload);
};

export const uploadFaceRekognitionApi = (userId, payload) => {
  return axiosInstance.post(`/acls/upload-face-rekognition/${userId}`, payload);
};

export const disableFaceRekognitionApi = (userId) => {
  return axiosInstance.patch(`/acls/disable-face-rekognition/${userId}`);
}

export const compareFaceApi = (payload) => {
  return axiosInstance.post(`/auth/compare-face`, payload);
};
export const changeUserPasswordApi = (payload) => {
  return axiosInstance.patch(`/acls/change-password`, payload);
};
export const forgotPasswordApi = (payload) => {
  return axiosInstance.post(`/auth/forgot-password`, payload);
};
export const deleteUserApi = (id) => {
  return axiosInstance.delete(`/acls/${id}`);
};

export const createGroupApi = (payload) => {
  return axiosInstance.post(`/group`, payload);
};
export const editGroupApi = (id, payload) => {
  return axiosInstance.put(`/group/${id}`, payload);
};
export const deleteGroupApi = (id) => {
  return axiosInstance.delete(`/group/${id}`);
};
export const getGroupsApi = (query) => {
  return axiosInstance.get(`/group`, { params: query });
};
export const getGroupByIDApi = (id) => {
  return axiosInstance.get(`/group/${id}`);
};
export const deleteMemberFromApi = (groupID, id) => {
  return axiosInstance.delete(`/group/${groupID}/${id}`);
};
export const getUsersApi = (query) => {
  return axiosInstance.get(`/search?${query}`);
};
export const getUsersandGroupsApi = (query) => {
  return axiosInstance.get(`/search/users-groups`, { params: query });
};
export const getMetaDataApi = () => {
  return axiosInstance.get(`/acls/metadata`);
};
export const updateNotificationApi = (payload) => {
  return axiosInstance.post(`/events`, payload);
};
export const resendUserApi = (id) => {
  return axiosInstance.patch(`/acls/re-invite/${id}`);
};

export const setPasswordApi = (payload) => {
  return axiosInstance.patch(`/acls/set-password`, payload);
};
