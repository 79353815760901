import {
  Breadcrumb,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Tag,
  Typography,
  Upload,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import dayjs from "dayjs";
import {
  getAllAssetsApi,
  getAssetLocationByIDApi,
} from "../../../services/assets.services";
import { useParams } from "react-router-dom";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import {
  deleteDocs,
  getAssetsPath,
  uploadFunc,
  uploadVideo,
} from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";
import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const { Dragger } = Upload;
import Compressor from "compressorjs";
import { getTaskTypeApi } from "../../../services/organisation.services";

const GeneralDetails = ({
  mroImage,
  mroDetails,
  editMode,
  setMroImage,
  updateMroImage,
}) => {
  const params = useParams();
  const [assets, setAssets] = useState([]);
  const [location, setLocation] = useState([]);
  const [image, setImage] = useState({ name: "", status: "" });
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [mroTypes, setMroTypes] = useState([]);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: "image_file",
    multiple: false,
    accept: "image/*",
    action: null,
    showUploadList: false,
    beforeUpload(file) {
      const isValidFileType = file.type.startsWith("image/");
      if (!isValidFileType) {
        message.error("You can only upload image file.");
        return Upload.LIST_IGNORE;
      }

      return new Promise((resolve, reject) => {
        new Compressor(file, {
          convertSize: 1000000,
          quality: 0.8,
          success(compressedFile) {
            resolve(compressedFile);
          },
          error(err) {
            message.error("Compression failed: " + err.message);
            reject(err);
          },
        });
      });
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        uploadImage(info);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (info.file.status === "error") {
        message.error("Image upload failed.");
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleRemove = (file) => {
    setMroImage(null);
    message.success(`${file.name} removed successfully.`);
  };

  const uploadImage = async (info) => {
    try {
      const resp = await uploadFunc(false, info, storageType);
      if (resp) {
        setMroImage(resp?.path);
      } else {
        message.error("File upload failed.");
      }
    } catch (error) {}
  };

  const getAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          code: asset?.code,
          value: asset?._id,
          image: asset?.file,
          status: asset?.status,
        }))
      );
    } catch (error) {}
  };
  const fetchMROTypes = async () => {
    try {
      const resp = await getTaskTypeApi();
      const transformedData = resp.data.map((item) => ({
        label: item.name,
        value: item._id,
      }));
      setMroTypes(transformedData);
    } catch (error) {
      console.error("Error fetching MRO types:", error);
    }
  };

  const getLocation = async (id) => {
    try {
      const resp = await getAssetLocationByIDApi(id);
      if (resp?.data) {
        setLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAssets();
    fetchMROTypes();
  }, []);

  useEffect(() => {
    const assetId = mroDetails?.asset?._id;
    if (assetId) {
      getLocation(assetId);
      let currAssetObj = assets?.filter((i) => i?.value === assetId)?.[0];
      setImage({
        name: currAssetObj?.image,
        status: currAssetObj?.status,
      });
    }
  }, [mroDetails]);

  const handleAssetChange = async (value, e) => {
    getLocation(value);
    if (e?.image) {
      setImage({
        name: e?.image,
        status: e?.status,
      });
    } else {
      setImage({ name: "", status: "" });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const renderImageWithClose = () => (
    <div style={{ position: "relative" }}>
      <Form.Item label="Issue Image">
        <Image
          width="50%"
          src={getAssetsPath(mroImage, storageType)}
          preview={true}
          style={{ width: "100%", height: "100%" }}
        />
        <CloseCircleOutlined
          style={{
            fontSize: 16,
            position: "absolute",
            top: 0,
            right: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            setMroImage("");
            updateMroImage();
          }}
        />
      </Form.Item>
    </div>
  );

  const renderDragger = () => (
    <Form.Item name={"image_file"} label="Issue Image">
      <Dragger customRequest={dummyRequest} {...props} onRemove={handleRemove}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Upload image (compressed automatically for large files).
        </p>
      </Dragger>
    </Form.Item>
  );

  const renderStaticImage = () => (
    <Form.Item label="Issue Image">
      {mroImage ? (
        <Image
          width="50%"
          src={getAssetsPath(mroImage, storageType)}
          style={{ width: "100%", height: "100%" }}
          alt="Issue image"
        />
      ) : (
        <Empty
          description="No issue image"
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </Form.Item>
  );

  return (
    <Card
      title="MRO Basic Details"
      styles={{ header: { backgroundColor: "#fff" } }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Flex style={{ width: "100%", gap: 6 }} vertical>
            <Form.Item
              label={"Asset"}
              name={"asset"}
              rules={[{ required: true, message: "Please select asset" }]}
            >
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select asset"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.name
                    ?.toUpperCase()
                    .includes(inputValue.toUpperCase()) ||
                  option.code?.toString().includes(inputValue)
                }
                onChange={handleAssetChange}
                options={assets}
              />
            </Form.Item>
            {location?.length > 0 && (
              <Form.Item label="Location">
                <div
                  style={{
                    borderRadius: "8px",
                    border: "0px ",
                    padding: "4px 12px",
                    width: "fit-content",
                  }}
                >
                  <ConfigProvider
                    theme={{
                      components: {
                        Breadcrumb: {
                          itemColor: "#2f3542",
                          lastItemColor: "#2f3542",
                        },
                      },
                    }}
                  >
                    <Breadcrumb separator=">" items={location} />
                  </ConfigProvider>
                </div>
              </Form.Item>
            )}
            <Form.Item
              label="Issue description"
              name={"description"}
              rules={[
                {
                  whitespace: true,
                  message:
                    "Issue description cannot be empty or contain only spaces",
                },
                { required: true, message: "Please enter issue description" },
              ]}
            >
              <Input.TextArea
                style={{ width: "100%" }}
                placeholder="Enter issue description here"
                disabled={params?.id ? true : false}
                className={inputStyles.smInput}
                rows={4}
              />
            </Form.Item>

            {editMode
              ? mroImage
                ? renderImageWithClose()
                : renderDragger()
              : params?.id
              ? renderStaticImage()
              : mroImage
              ? renderImageWithClose()
              : renderDragger()}

            <Form.Item label={"Type"} name={"typeObj"}>
              <Select
                // disabled={params?.id && (!editMode ||( mroDetails?.workorders?.length !== 0 &&
                //   mroDetails?.need_verification !== "verified")) ? true : false}
                disabled={
                  params?.id &&
                  (!editMode ||
                    mroDetails?.workorders?.length !== 0 ||
                    mroDetails?.need_verification === "verified")
                    ? true
                    : false
                }
                placeholder="Select type"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                // allowClear={!params?.id ? true : false}
                showSearch={true}
                optionFilterProp="label"
                options={mroTypes}
                allowClear
              />
            </Form.Item>

            <Form.Item
              valuePropName="checked"
              name={"is_breakdown"}
              style={{ cursor: params?.id ? "not-allowed" : "auto" }}
            >
              <Checkbox style={{ pointerEvents: params?.id ? "none" : "auto" }}>
                Production impacted and breakdown occurred
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Scheduled to start at"
              name={"start_date"}
              rules={[
                { required: true, message: "Please select start date & time" },
              ]}
            >
              <DatePicker
                disabled={params?.id ? true : false}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select start date & time"
                size="medium"
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            {/* <Form.Item label="Whom to be notify" name={"notify"}>
              <Select
                disabled={params?.id ? true : false}
                placeholder="Select whom to be notify"
                style={{ width: "100%" }}
                className={inputStyles.smSelect}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.name
                    ?.toUpperCase()
                    .includes(inputValue.toUpperCase()) ||
                  option.code?.toString().includes(inputValue)
                }
                options={[]}
              />
            </Form.Item> */}
          </Flex>
        </Col>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "12px",
            }}
          >
            {image?.status && (
              <Typography>
                Asset status:{" "}
                <Tag className={tagStyles?.smTag}> {image.status}</Tag>
              </Typography>
            )}
            {image?.name ? (
              <Image
                src={getAssetsPath(image.name, storageType)}
                alt="Asset"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "12px",
                }}
              />
            ) : (
              <Empty
                description="No asset images"
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
