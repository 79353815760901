import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  Typography,
  DatePicker,
  message,
  Modal,
  Space,
  Radio,
  Alert,
} from "antd";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  workOrderstatusList,
} from "../../../helpers/utility";
import dayjs from "dayjs";
import { updateOccurenceApi } from "../../../services/maintenance.services";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserProvider";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { INSPECTION } from "../../../constants/defaultKeys";

const MaintananceStatus = ({ type, maintanaceDetails, refreshCall }) => {
  const [jobStatus, setJobStatus] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const params = useParams();
  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    if (jobStatus && jobStatus !== maintanaceDetails?.status && !showModal) {
      updateJobStatus();
    }
  }, [jobStatus]);

  const updateJobStatus = async (dateVal = null) => {
    let payload = { status: jobStatus };
    if (jobStatus === "in-progress" && dateVal) {
      payload = { ...payload, started_at: convertToUTCFormat(dateVal) };
    } else if (jobStatus === "closed") {
      payload = { ...payload, ended_at: convertToUTCFormat(dateVal) };
    }

    try {
      const resp = await updateOccurenceApi(params?.id, payload);
      if (resp?.data) {
        message.success(resp?.data?.message);
        refreshCall();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    }
  };

  const handleDisabledDate = (current) => {
    if (jobStatus === "in-progress" && maintanaceDetails?.start_date) {
      const scheduledStartDate = maintanaceDetails?.start_date;
      return (
        current &&
        (current.isBefore(scheduledStartDate, "day") ||
          current > dayjs().endOf("day"))
      );
    } else if (jobStatus === "closed" && maintanaceDetails?.started_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(maintanaceDetails?.started_at)
      );
      return (
        current &&
        (current.isBefore(startDate, "day") || current > dayjs().endOf("day"))
      );
    } else {
      return false;
    }
  };

  const handleDisabledTime = (current) => {
    if (
      (jobStatus === "in-progress" && maintanaceDetails?.start_date) ||
      (jobStatus === "closed" && maintanaceDetails?.started_at)
    ) {
      let startDate = null;
      if (jobStatus === "in-progress" && maintanaceDetails?.start_date) {
        startDate = maintanaceDetails?.start_date;
      } else if (jobStatus === "closed" && maintanaceDetails?.started_at) {
        startDate = dayjs(
          convertUTCToLocalFormat(maintanaceDetails?.started_at)
        );
      }

      if (!startDate) {
        return {};
      }
      let endDate = dayjs();
      const startHours = startDate.hour();
      const startMinutes = startDate.minute();
      const endHours = endDate.hour();
      const endMinutes = endDate.minute();

      if (
        current &&
        current.isSame(startDate, "day") &&
        current.isSame(endDate, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < startHours || hour > endHours
            ),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours && startHours == endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes || min > endMinutes
              );
            }
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes
              );
            }
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes
              );
            }
            return [];
          },
        };
      }
      if (current && current.isSame(startDate, "day")) {
        return {
          disabledHours: () => Array.from({ length: startHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: startMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  return (
    <Card
      title={"Status"}
      styles={{
        body: { padding: "12px" },
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row
        gutter={[12, 16]}
        style={{
          width: "100%",
          justifyItems: "center",
        }}
      >
        <Col span={24}>
          <Row style={{ border: "1px solid #eaecf0", borderRadius: 6 }}>
            {workOrderstatusList
              ?.filter((each) => {
                if (
                  maintanaceDetails?.status !== "pending" &&
                  each?.value === "pending"
                ) {
                  return false;
                } else if (
                  maintanaceDetails?.status === "pending" &&
                  each?.value === "open"
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              ?.map((status, index) => {
                return (
                  <Col span={6} key={index}>
                    <Button
                      ghost={
                        maintanaceDetails?.status == status?.value
                          ? true
                          : false
                      }
                      type={
                        maintanaceDetails?.status == status?.value
                          ? "primary"
                          : "default"
                      }
                      style={{
                        height: "74px",
                        width: "100%",
                        padding: "2px",
                        whiteSpace: "wrap",
                        backgroundColor:
                          maintanaceDetails?.status == status?.value &&
                          status?.color,
                        color:
                          maintanaceDetails?.status == status?.value && "#fff",
                        border: 0,
                        pointerEvents: "none",
                        borderRadius:
                          maintanaceDetails?.status == status?.value ? 6 : 0,
                      }}
                    >
                      {status?.icon}
                      <br />
                      {status.label}
                    </Button>
                  </Col>
                );
              })}
          </Row>
        </Col>
        {["open", "pending"].includes(maintanaceDetails?.status) && (
          <Col span={24}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                if (
                  maintanaceDetails?.start_date &&
                  maintanaceDetails?.start_date > dayjs()
                ) {
                  message.warning(
                    `You cannot start this ${
                      type == INSPECTION ? "Inspection" : "PM"
                    } because it is scheduled for a future date and time`
                  );
                } else {
                  setJobStatus("in-progress");
                  setSelectedDate(dayjs());
                  // setSelectedDate(
                  //   maintanaceDetails?.start_date
                  //     ? maintanaceDetails?.start_date
                  //     : dayjs()
                  // );
                  setShowModal(true);
                }
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Start {type == INSPECTION ? "Inspection" : "PM"}
            </Button>
          </Col>
        )}
        {maintanaceDetails?.status === "in-progress" && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setJobStatus("on-hold");
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              On Hold
            </Button>
          </Col>
        )}
        {maintanaceDetails?.status === "on-hold" && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setJobStatus("in-progress");
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Resume
            </Button>
          </Col>
        )}
        {["in-progress", "on-hold"].includes(maintanaceDetails?.status) && (
          <Col span={12}>
            <Button
              className={btnStyles.mdBtn}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                setJobStatus("closed");
                setSelectedDate(dayjs());
                // setSelectedDate(
                //   maintanaceDetails?.started_at
                //     ? dayjs(convertToUTCFormat(maintanaceDetails?.started_at))
                //     : dayjs()
                // );
                setShowModal(true);
              }}
              disabled={userDetails?.activeOrg?.role === "operator"}
            >
              Closed
            </Button>
          </Col>
        )}
      </Row>
      {showModal && (
        <Modal
          title=""
          open={showModal}
          onCancel={() => {
            setShowModal(false);
            setSelectedOption(undefined);
          }}
          footer={null}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {(!maintanaceDetails?.inventory_request?.requested ||
              maintanaceDetails?.inventory_request?.requested?.length === 0) &&
              jobStatus === "closed" && (
                <>
                  <Typography>
                    Did you use any spare part for this{" "}
                    {type == INSPECTION ? "Inspection" : "PM"} ?
                  </Typography>
                  <Radio.Group
                    style={{ marginBottom: 15 }}
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e?.target?.value)}
                  >
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                  {selectedOption === "yes" && (
                    <Alert
                      type="info"
                      message={`Please add the used spare part details before closing ${
                        type == INSPECTION ? "Inspection" : "PM"
                      }`}
                    />
                  )}
                </>
              )}
            {(selectedOption === "no" ||
              (maintanaceDetails?.inventory_request?.requested &&
                maintanaceDetails?.inventory_request?.requested?.length > 0) ||
              jobStatus !== "closed") && (
              <>
                <Typography>{`${type == INSPECTION ? "Inspection" : "PM"} ${
                  jobStatus === "closed" ? "closed" : "started"
                } at`}</Typography>
                <DatePicker
                  format="YYYY-MM-DD HH:mm"
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  placeholder="Select date"
                  size="medium"
                  allowClear={false}
                  showTime={{
                    defaultValue: dayjs("00:00", "HH:mm"),
                  }}
                  style={{
                    width: "70%",
                  }}
                  showNow={false}
                  disabledDate={handleDisabledDate}
                  disabledTime={handleDisabledTime}
                />
                <Button
                  type="primary"
                  className={btnStyles.smBtn}
                  style={{ float: "right", marginTop: 10 }}
                  onClick={() => {
                    setShowModal(false);
                    setSelectedOption(undefined);
                    updateJobStatus(selectedDate);
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </Space>
        </Modal>
      )}
    </Card>
  );
};

export default MaintananceStatus;
