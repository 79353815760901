import { PictureOutlined } from "@ant-design/icons";
import { Popover, Upload, message } from "antd";
import React, { useContext } from "react";
import { uploadFunc } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";
import Compressor from "compressorjs";

const UploadSectionDocs = ({ sopContent, setSopContent, questionData }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const props = {
    name: "file",
    multiple: false,
    beforeUpload(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.8, // Adjust quality between 0 and 1
          convertSize: 1000000,
          success(compressedFile) {
            resolve(compressedFile); // Resolve with the compressed file
          },
          error(err) {
            console.error("Compression failed:", err.message);
          },
        });
      });
    },
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const customOnChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      uploadImage(info);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadImage = async (info, key) => {
    const resp = await uploadFunc(false, info, storageType);
    if (resp) {
      if (questionData.id) {
        setSopContent((prevSopContent) =>
          prevSopContent.map((eachSOP) =>
            eachSOP.id === questionData.parentId
              ? {
                  ...eachSOP,
                  procedures: eachSOP.procedures.map((eachQ) =>
                    eachQ.id === questionData.id
                      ? {
                          ...eachQ,
                          images: [...(eachQ?.images || []), resp?.path],
                        }
                      : eachQ
                  ),
                }
              : eachSOP
          )
        );
      }
    }
  };

  return (
    <>
      {questionData?.images?.length > 1 ? (
        <Popover content="Maximum 2 images can be uploaded">
          {" "}
          <PictureOutlined />
        </Popover>
      ) : (
        <Upload
          showUploadList={false}
          maxCount={4}
          accept="image/*"
          className="assetsUpload"
          {...props}
          onChange={customOnChange}
          customRequest={dummyRequest}
        >
          <PictureOutlined />
        </Upload>
      )}
    </>
  );
};

export default UploadSectionDocs;
