import { Button, Card, Col, Form, Row, Upload, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import "../Assets.css";
import {
  deleteDocs,
  getAssetsPath,
  renameFile,
  uploadFunc,
} from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE } from "../../../constants/defaultKeys";

const AssetDocuments = ({ form, documents, setDocuments, edit }) => {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [fileLists, setFileLists] = useState({
    warranty: [],
    maintenance: [],
    other_docs: [],
    pid_drawings: [],
    asset_3d_model: [],
    product_manuals: [],
  });
  const props = {
    name: "file",
  };

  const uploadImage = async (info, key) => {
    const resp = await uploadFunc(false, info, storageType);

    if (resp) {
      await form.setFieldValue(
        ["asset_docs", key],
        resp?.path ? [...(documents?.[key] || []), resp?.path] : []
      );
      setDocuments({
        ...documents,
        [key]: [...(documents?.[key] || []), resp?.path],
      });
    }
  };

  const customOnChange = (info, key) => {
    const { status } = info.file;
    if (status !== "uploading") {
      uploadImage(info, key);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const createFileLists = (documents) => {
    const newFileLists = {};

    for (const key in documents) {
      if (Object.hasOwnProperty.call(documents, key)) {
        const fileList = documents?.[key]?.map((doc, index) => {
          return {
            uid: index,
            name: doc,
            status: "done",
            url: getAssetsPath(doc, storageType),
          };
        });

        newFileLists[key] = fileList;
      }
    }

    return newFileLists;
  };

  useEffect(() => {
    const newFileLists = createFileLists(documents);
    setFileLists(newFileLists);
  }, [documents]);

  return (
    <Card
      title="Asset Documents"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        {[
          {
            fieldKey: "product_manuals",
            buttonText: "Upload asset manuals here",
          },
          {
            fieldKey: "pid_drawings",
            buttonText: "Upload P&ID drawings",
          },
          {
            fieldKey: "asset_3d_model",
            buttonText: "Upload asset 3D models",
          },
          {
            fieldKey: "other_docs",
            buttonText: " Upload other documents",
          },
        ].map((each, index) => {
          return (
            <Col span={12} key={index}>
              <Form.Item
                name={["asset_docs", each?.fieldKey]}
                style={{ margin: "0px", height: "0px" }}
              >
                <div></div>
              </Form.Item>
              <Form.Item label=" " style={{ width: "100%" }}>
                <Upload
                  accept={[".pdf"]}
                  showUploadList={false}
                  className="assetsUpload"
                  {...props}
                  onChange={(info) => customOnChange(info, each?.fieldKey)}
                  customRequest={dummyRequest}
                >
                  <Button
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                    className={btnStyles.mdBtn}
                  >
                    {each.buttonText}
                    <UploadOutlined />
                  </Button>
                </Upload>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "12px",
                  }}
                >
                  {fileLists?.[each?.fieldKey]?.map((e) => {
                    const fileName =
                      storageType == ONPREMISE ? e?.name : renameFile(e?.name);
                    return (
                      <div className={"fileListItem"}>
                        <a
                          href={e.url}
                          target="_blank"
                          style={{ color: "#101828" }}
                        >
                          <span>
                            {fileName.length > 40
                              ? `${fileName?.slice(0, 40)}...`
                              : fileName}
                          </span>
                        </a>
                        <CloseOutlined
                          style={{ cursor: !edit && "not-allowed" }}
                          onClick={async () => {
                            if (edit) {
                              deleteDocs(false, e?.name, storageType);
                              setDocuments({
                                ...documents,
                                [each?.fieldKey]: documents?.[
                                  each?.fieldKey
                                ]?.filter((id) => id != e?.name),
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default AssetDocuments;
