import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { getAssetCategories } from "../../../services/assets.services";
import { useNavigate } from "react-router-dom";
import { tableComponents } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";

const AssetCategories = () => {
  const navigate = useNavigate();
  const [assetcategories, setAssetCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(UserContext);

  const fetchAssetCategories = async () => {
    setLoading(true);
    try {
      const res = await getAssetCategories();
      setAssetCategories(res?.data);
    } catch (error) {
      setAssetCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssetCategories();
  }, []);

  const tableColumns = [
    {
      title: "Asset category name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 300,
      render: (val) => (val ? val : "-"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "No. of assets",
      dataIndex: "assets_count",
      key: "assets_count",
      render: (_, record) => record?.assets?.length,
    },
  ];

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Asset Categories ({assetcategories?.length || 0})
          </Typography>
        </Flex>
      }
    >
      <>
        <Row gutter={[24, 18]}>
          <Col span={24}>
            <Table
              title={() =>
                (userDetails?.activeOrg?.role == "admin" ||
                  userDetails?.activeOrg?.role == "manager") && (
                  <Space
                    align="center"
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      gap: 16,
                    }}
                  >
                    <Button
                      id="create asset category"
                      type="primary"
                      className={btnStyles.mdBtn}
                      onClick={() => navigate("/asset-categories/create")}
                    >
                      + Create Asset Category
                    </Button>
                  </Space>
                )
              }
              scroll={{
                x: 1200,
                y: "60vh",
              }}
              locale={{
                emptyText: loading ? (
                  <Skeleton active />
                ) : (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No asset categories found</Typography>}
                  />
                ),
              }}
              bordered
              pagination={false}
              onRow={(item) => {
                return {
                  onClick: (e) => {
                    navigate(`/asset-categories/${item?._id}`);
                  },
                  style: { cursor: "pointer" },
                };
              }}
              columns={tableColumns}
              dataSource={assetcategories}
              loading={
                loading
                  ? {
                      spinning: false,
                      indicator: null,
                    }
                  : false
              }
              components={tableComponents}
            />
          </Col>
        </Row>
      </>
    </CustomLayout>
  );
};

export default AssetCategories;
