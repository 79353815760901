import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import GeneralDetails from "./GeneralDetails";
import VerificationDetails from "./VerificationDetails";
import MroWorkOrders from "./MroWorkOrders";
import {
  createMroApi,
  deleteMroApi,
  getMroByIDApi,
  updateMroApi,
} from "../../../services/work-orders.services";
import dayjs from "dayjs";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  deleteDocs,
  renameFile,
} from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE, verifyMRO } from "../../../constants/defaultKeys";
import { Label } from "recharts";

const CreateMRO = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [mroDetails, setMroDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [mroImage, setMroImage] = useState(null);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const handleDisabledDate = (current) => {
    if (mroDetails?.workorders?.[0]?.ended_at) {
      const endDate = dayjs(
        convertUTCToLocalFormat(mroDetails?.workorders?.[0]?.ended_at)
      );
      return (
        current &&
        (current.isBefore(endDate, "day") || current > dayjs().endOf("day"))
      );
    } else {
      return false;
    }
  };

  const handleDisabledTime = (current) => {
    if (mroDetails?.workorders?.[0]?.ended_at) {
      const startDate = dayjs(
        convertUTCToLocalFormat(mroDetails?.workorders?.[0]?.ended_at)
      );
      let endDate = dayjs();
      if (!startDate) {
        return {};
      }

      const startHours = startDate.hour();
      const startMinutes = startDate.minute();
      const endHours = endDate.hour();
      const endMinutes = endDate.minute();

      if (
        current &&
        current.isSame(startDate, "day") &&
        current.isSame(endDate, "day")
      ) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).filter(
              (hour) => hour < startHours || hour > endHours
            ),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours && startHours == endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes || min > endMinutes
              );
            }
            if (selectedHour === startHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min < startMinutes
              );
            }
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).filter(
                (min) => min > endMinutes
              );
            }
            return [];
          },
        };
      }
      if (current && current.isSame(startDate, "day")) {
        return {
          disabledHours: () => Array.from({ length: startHours }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === startHours) {
              return Array.from({ length: startMinutes }, (_, i) => i);
            }
            return [];
          },
        };
      }
      if (current && current.isSame(endDate, "day")) {
        return {
          disabledHours: () =>
            Array.from({ length: 24 }, (_, i) => i).splice(endHours + 1),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === endHours) {
              return Array.from({ length: 60 }, (_, i) => i).splice(
                endMinutes + 1
              );
            }
            return [];
          },
        };
      }
      return {};
    } else {
      return {};
    }
  };

  const fetchMroDetails = async () => {
    try {
      const res = await getMroByIDApi(params?.id);
      let data = res?.data;
      setMroDetails(res?.data);
      form.setFieldsValue({
        description: data?.description,
        asset: data?.asset?._id,
        is_breakdown: data?.is_breakdown ? true : false,
        start_date: data?.start_date
          ? dayjs(convertUTCToLocalFormat(data?.start_date))
          : null,
        typeObj: data?.typeObj ? data?.typeObj?._id : undefined,
        // typeObj: data?.typeObj
        //   ? {
        //       label: data?.typeObj?.name,
        //       value: data?.typeObj?._id,
        //     }
        //   : undefined,
        need_verification: data?.need_verification ? true : false,
      });
      setMroImage(data?.image_file);
    } catch (error) {
      navigate("/mro");
    }
  };

  useEffect(() => {
    params?.id && fetchMroDetails();
    !params?.id && form.setFieldValue("start_date", dayjs());
  }, [params]);

  const onFinish = async (values) => {
    let payload = {};
    if (!params?.id) {
      payload = {
        ...payload,
        description: values?.description,
        asset_id: values?.asset,
        is_breakdown: values?.is_breakdown ? true : false,
        start_date: values?.start_date
          ? convertToUTCFormat(values?.start_date)
          : "",
      };
    }
    if (values?.typeObj) {
      payload = {
        ...payload,
        typeObj: values?.typeObj,
      };
    }

    if (params?.id) {
      payload = {
        ...payload,
        typeObj: values?.typeObj || "",
      };
    }
    payload = {
      ...payload,
      image_file:
        mroImage && mroImage !== ""
          ? storageType == ONPREMISE
            ? mroImage
            : renameFile(mroImage)
          : "",
    };
    try {
      const resp = params?.id
        ? await updateMroApi(params?.id, payload)
        : await createMroApi(payload);
      if (resp?.data) {
        if (params?.id) {
          message.success("MRO updated successfully");
          fetchMroDetails();
          setEditMode(false);
        } else {
          message.success("MRO created successfully");
          navigate("/mro");
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const updateMroImage = async () => {
    try {
      if (mroImage) {
        const resp = await updateMroApi(params?.id, { image_file: "" });
        if (resp) {
          deleteDocs(false, mroImage, storageType);
          setMroImage("");
          message.success("Image deleted successfully");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateMro = async (type, dateVal = null) => {
    let payload = {};
    if (type === "resolve" && dateVal) {
      payload = {
        ...payload,
        status: "resolved",
        ended_at: convertToUTCFormat(dateVal),
      };
    } else if (type === "verify") {
      payload = { ...payload, mark_as_verified: true };
    }

    try {
      const res = await updateMroApi(params?.id, payload);
      if (res?.data) {
        message.success(
          `MRO is ${type === "resolve" ? "resolved" : "verified"}`
        );
        fetchMroDetails();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  const deleteMro = async () => {
    try {
      const resp = await deleteMroApi(params?.id);
      resp && navigate("/mro");
      message.success(resp?.data?.message);
    } catch (error) {
      message.error(error?.repsonse?.data?.message);
    }
  };

  return (
    <CustomLayout
      header={
        <Flex justify="space-between" align="center">
          <Flex justify="flex-start" align="center" style={{ gap: 6 }}>
            <LeftOutlined
              className={typoStyles.h6}
              onClick={() => navigate("/mro")}
            />
            <Typography className={typoStyles.h6}>
              {params?.id ? mroDetails?.code : "Create MRO"}
            </Typography>
            {mroDetails?.mark_as_verified && (
              <Tag className={tagStyles.mdRndTag} color="green">
                Verified
              </Tag>
            )}
          </Flex>
          <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
            {params?.id &&
              ["admin", "manager"].includes(userDetails?.activeOrg?.role) && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to delete this MRO?"
                  onConfirm={(e) => {
                    deleteMro();
                  }}
                >
                  <Button className={btnStyles.mdBtn}>
                    <span className="hiddenBelowTab">Delete</span>
                    <DeleteOutlined style={{ margin: "0px" }} />
                  </Button>
                </Popconfirm>
              )}
            {params?.id && !editMode && mroDetails?.status !== "resolved" && (
              <Button
                className={btnStyles.mdBtn}
                onClick={() => setEditMode(true)}
                type="primary"
              >
                <span className="hiddenBelowTab">Edit</span>
                <EditOutlined style={{ margin: "0px" }} />
              </Button>
            )}
          </div>
        </Flex>
      }
      footer={
        <>
          {(!params?.id || (params?.id && editMode)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEditMode(false);
                    fetchMroDetails();
                  } else {
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <>
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[24, 24]}>
            <Col xs={24} md={24} lg={24} xl={16}>
              <Flex style={{ width: "100%", gap: 24 }} vertical>
                <GeneralDetails
                  form={form}
                  setMroImage={setMroImage}
                  mroImage={mroImage}
                  mroDetails={mroDetails}
                  editMode={editMode}
                  updateMroImage={updateMroImage}
                />
              </Flex>
            </Col>
            {params?.id && (
              <Col xs={24} md={24} lg={24} xl={8}>
                <Flex style={{ width: "100%", gap: 24 }} vertical>
                  <MroWorkOrders
                    mroDetails={mroDetails}
                    fetchMroDetails={fetchMroDetails}
                  />
                  <VerificationDetails mroDetails={mroDetails} />
                </Flex>
              </Col>
            )}
            {params?.id &&
              (mroDetails?.verification_steps?.[0]?.verifiers
                ?.map((i) => i?._id)
                ?.includes(userDetails?._id) ||
                mroDetails?.creator?._id === userDetails?._id ||
                userDetails?.activeOrg?.role === "admin") &&
              mroDetails?.status !== "resolved" &&
              mroDetails?.workorders?.[0]?.status === "closed" && (
                <Col
                  xs={24}
                  md={24}
                  lg={24}
                  xl={16}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    className={btnStyles.mdBtn}
                    style={{ width: "40vw" }}
                    type="primary"
                    onClick={() => {
                      setSelectedDate(dayjs());
                      // setSelectedDate(
                      //   mroDetails?.workorders?.[0]?.ended_at
                      //     ? dayjs(
                      //         convertUTCToLocalFormat(
                      //           mroDetails?.workorders?.[0]?.ended_at
                      //         )
                      //       )
                      //     : dayjs()
                      // );
                      setShowModal(true);
                    }}
                  >
                    Resolved
                  </Button>
                </Col>
              )}
            {params?.id &&
              (userDetails?.activeOrg?.role === "admin" ||
                (mroDetails?.verification_steps?.[0]?.verifiers?.length > 0 &&
                  mroDetails?.verification_steps?.[0]?.verifiers
                    ?.map((i) => i?._id)
                    ?.includes(userDetails?._id)) ||
                (!mroDetails?.verification_steps?.[0]?.verifiers?.length &&
                  verifyMRO.includes(userDetails?.activeOrg?.role))) &&
              mroDetails?.status === "resolved" &&
              !mroDetails?.mark_as_verified && (
                <Col
                  xs={24}
                  md={24}
                  lg={24}
                  xl={16}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    className={btnStyles.mdBtn}
                    style={{ width: "40vw", backgroundColor: "#87d068" }}
                    type="primary"
                    onClick={() => onUpdateMro("verify")}
                  >
                    Mark as Verified <CheckOutlined />
                  </Button>
                </Col>
              )}
          </Row>
        </Form>
        {showModal && (
          <Modal
            title=""
            open={showModal}
            onCancel={() => setShowModal(false)}
            footer={null}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Typography>MRO resolved at</Typography>
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                placeholder="Select date"
                size="medium"
                allowClear={false}
                showTime={{
                  defaultValue: dayjs("00:00", "HH:mm"),
                }}
                style={{
                  width: "70%",
                }}
                showNow={false}
                disabledDate={handleDisabledDate}
                disabledTime={handleDisabledTime}
              />
              <Button
                type="primary"
                className={btnStyles.smBtn}
                style={{ float: "right", marginTop: 10 }}
                onClick={() => {
                  setShowModal(false);
                  onUpdateMro("resolve", selectedDate);
                }}
              >
                Submit
              </Button>
            </Space>
          </Modal>
        )}
      </>
    </CustomLayout>
  );
};

export default CreateMRO;
